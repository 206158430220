import { WishPanel } from '../Wish/WishPanel';
import { WishTitle } from '../Wish/WishTitle';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Background from '../../assets/background/airmail.jpg';

const useStyles = makeStyles()((theme) => ({
  dialog: {
    backgroundImage: `url('${Background}')`,
    backgroundSize: 'cover'
  },
  content: {
    margin: theme.spacing(2),
    backgroundColor: '#fff',
  }
}));

export const PanelWishModal = (props: any) => {
  const { classes } = useStyles();
  return (
    <Dialog maxWidth="lg" fullWidth open={props.open} onClose={() => props.closeModal()} PaperProps={{ classes: { root: classes.dialog }, style: {}, }}>
      <div className={classes.content}>
        <Grid container justifyContent="space-between">
          <Grid item><DialogTitle><WishTitle wishType={props.selectedWish?.wishType} /></DialogTitle></Grid>
          <Grid item><Tooltip title="close"><IconButton color="primary" onClick={() => props.closeModal()}><CancelIcon /></IconButton></Tooltip></Grid>
        </Grid>
        <DialogContent style={{ paddingTop: 0 }} >
          <WishPanel selectedWish={props.selectedWish} />
        </DialogContent>
      </div>
    </Dialog >
  );
};
