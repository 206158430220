import { useState, useEffect } from 'react';

import { createUserProfile } from '../../../services/users';
import { useApiGroupList } from '../../../hooks/admin/useApiGroupList';

import { AppMessage } from '../../../shared/UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';

const DEFAULT_PICTURE_URL = 'https://assets.cloud.leatherwish.com/profile/default.png';

const useStyles = makeStyles()((theme) => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(0.5)
  },
}));

export const CreateUserModal = (props: any) => {
  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useApiGroupList();

  const [groupId, setGroupId] = useState('');
  const [roles, setRoles] = useState('');
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');
  const [password, setPassword] = useState('');

  const [errorGroup, setErrorGroup] = useState('');
  const [errorRoles, setErrorRoles] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorUsername, setErrorUsername] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPictureUrl, setErrorPictureUrl] = useState(DEFAULT_PICTURE_URL);
  const [errorPhone, setErrorPhone] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const clearForm = () => {
    setGroupId(props?.selectedGroup?.groupId || '');
    setRoles('');
    setName('');
    setUsername('');
    setEmail('');
    setPhone('');
    setPictureUrl(DEFAULT_PICTURE_URL);
    setPassword('');
    setShowPassword(false);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorGroup('');
    setErrorRoles('');
    setErrorName('');
    setErrorUsername('');
    setErrorEmail('');
    setErrorPhone('');
    setErrorPictureUrl('');
    setErrorPassword('');
  };

  useEffect(() => {
    setGroupId(props?.selectedGroup?.groupId || '');
    setRoles('');
    setName('');
    setUsername('');
    setEmail('');
    setPhone('');
    setPictureUrl(DEFAULT_PICTURE_URL);
    setPassword('');
    setShowPassword(false);
    setMessage({ type: 'info', description: '' });
    setErrorGroup('');
    setErrorRoles('');
    setErrorName('');
    setErrorUsername('');
    setErrorEmail('');
    setErrorPhone('');
    setErrorPictureUrl('');
    setErrorPassword('');
  }, [props?.selectedGroup]);

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (groupId.length === 0) {
      setErrorGroup('Select the group');
      return false;
    }
    if (name.length === 0) {
      setErrorName('Enter the name');
      return false;
    }
    if (username.length === 0) {
      setErrorUsername('Enter the username');
      return false;
    }
    if (email.length === 0) {
      setErrorEmail('Enter the email');
      return false;
    }
    if (phone.length === 0) {
      setErrorPhone('Enter the phone');
      return false;
    }
    if (pictureUrl.length === 0) {
      setErrorPictureUrl('Enter the pictureUrl');
      return false;
    }
    if (password.length === 0) {
      setErrorPassword('Enter the password');
      return false;
    }
    if (password.length < 8) {
      setErrorPassword('Enter a password with a minimum of 8 characters');
      return false;
    }
    if (!password.match(/[a-z]+/)) {
      setErrorPassword('Enter a password with a lower case character');
      return false;
    }
    if (!password.match(/[A-Z]+/)) {
      setErrorPassword('Enter a password with an upper case character');
      return false;
    }
    if (!password.match(/[0-9]+/)) {
      setErrorPassword('Enter a password with a numeric character');
      return false;
    }
    if (!password.match(/^[a-zA-Z0-9]+/)) {
      setErrorPassword('Enter a password with a special character');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleAddNew();
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating User: <strong>${name}</strong> ... ` });
    createUserProfile(name.trim(), username.trim(), email.trim(), phone.trim(), pictureUrl.trim(), password, groupId, roles.trim()).then((result: any) => {
      if (result.status) {
        setMessage({ type: 'success', description: `User: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(result.data);
      } else {
        setMessage({ type: 'error', description: result.message });
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const handleMutate = () => {
    mutateGroupList();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <DialogTitle>Create User</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          {isLoadingGroupList && (
            <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
          )}
          {!isLoadingGroupList && (
            <Tooltip title="refresh"><IconButton color="primary" onClick={() => handleMutate()}><RefreshIcon /></IconButton></Tooltip>
          )}
        </Grid>
      </Grid>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl className={classes.formcontrol} fullWidth>
                <InputLabel id='groupId-label'>group</InputLabel>
                <Select
                  labelId='groupId-label'
                  id='groupId'
                  label="group"
                  value={groupId}
                  onChange={(e: any) => setGroupId(e.target.value)}
                  autoFocus
                >
                  {dataGroupList?.map((group: any) => (
                    <MenuItem key={group.groupId} value={group.groupId}>{group.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText className={classes.errorText}>{errorGroup}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formcontrol} fullWidth>
                <InputLabel id='role-label'>role</InputLabel>
                <Select
                  labelId='role-label'
                  id='roles'
                  label="role"
                  value={roles}
                  onChange={(e: any) => setRoles(e.target.value)}
                >
                  <MenuItem value='administrator'>Administrator</MenuItem>
                  <MenuItem value='director'>Store Director</MenuItem>
                  <MenuItem value='manager'>Leather Manager</MenuItem>
                  <MenuItem value='reviewer'>SSS Reviewer</MenuItem>
                  <MenuItem value='metier'>Métier Line Manager</MenuItem>
                  <MenuItem value='advisor'>Client Advisor</MenuItem>
                  <MenuItem value='cashier'>Cashier</MenuItem>
                </Select>
                <FormHelperText className={classes.errorText}>{errorRoles}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={(e: any) => setName(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id='username'
                label='username'
                value={username}
                error={errorUsername.length > 0}
                helperText={errorUsername}
                onChange={(e: any) => setUsername(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='phone'
                label='phone'
                value={phone}
                error={errorPhone.length > 0}
                helperText={errorPhone}
                onChange={(e: any) => setPhone(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>

          <TextField
            id='email'
            label='email'
            value={email}
            error={errorEmail.length > 0}
            helperText={errorEmail}
            onChange={(e: any) => setEmail(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />

          <TextField
            id='pictureUrl'
            label='picture'
            value={pictureUrl}
            error={errorPictureUrl.length > 0}
            helperText={errorPictureUrl}
            onChange={(e: any) => setPictureUrl(e.target.value.trim())}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            InputProps={{
              endAdornment: (
                <div style={{ width: 32, height: 32, backgroundSize: 'cover', backgroundImage: `url('${pictureUrl}')` }} />
              )
            }}
          />

          <TextField
            id='password'
            label='password'
            value={password}
            error={errorPassword.length > 0}
            helperText={errorPassword}
            onChange={(e: any) => setPassword(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
