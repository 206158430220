import { WishTable } from './WishTable';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: 0,
    marginTop: theme.spacing(3),
  },
  scroll: {
    overflowX: 'scroll',
    scrollbarWidth: 'thin'
  }
}));

const dateIsEqual = (year: number, month: number, day: number, ds2: string) => {
  if (year && month && day && ds2) {
    const dt1 = new Date(year, month, day, 12, 0, 0, 0);
    const dt2 = new Date(ds2);
    dt2.setHours(12, 0, 0, 0);
    return (dt1.getTime() === dt2.getTime());
  }
  return false;
}
export const Day = (props: any) => {
  const { year, month, day, data, isLoading, mutate, selectWish, selectedWish, selectedGroup, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handleEdit, handleDelete, handlePanel } = props;

  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <div className={classes.scroll}>
        <WishTable
          year={year}
          month={month}
          day={day}
          data={data?.filter((x: any) => dateIsEqual(year, month, day, x.scheduleDate))}
          isLoading={isLoading}
          mutate={mutate}
          selectWish={selectWish}
          selectedWish={selectedWish}
          selectedGroup={selectedGroup}
          handleReview={handleReview}
          handleApprove={handleApprove}
          handleSchedule={handleSchedule}
          handleDeliver={handleDeliver}
          handleCancel={handleCancel}
          handleState={handleState}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handlePanel={handlePanel}
        />
      </div>
    </Paper>
  );
};