import { useState } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { useAuthStore } from '../../../hooks/context/useAuthStore';
import { canCreateUser, canCreateGroup, canEditGroup, canDeleteGroup } from '../../../helpers/security';

import { EditGroupModal } from './EditGroupModal';
import { DeleteGroupModal } from './DeleteGroupModal';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
}));

export const GroupTable = (props: any) => {
  const { data, isLoading, mutate, selectGroup, selectedGroup } = props;
  const { currentUser } = useAuthStore();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleAddNew = () => {
    console.log('handleAddNew');
    selectGroup();
    setOpenEditModal(true);
  };

  const handleOpenEditModal = (id: string) => {
    console.log('handleOpenEditModal', id);
    selectGroup(id);
    setOpenEditModal(true);
  };

  const handleOpenDeleteModal = (id: string) => {
    console.log('handleOpenDeleteModal', id);
    selectGroup(id);
    setOpenDeleteModal(true);
  };


  const submitEdit = (id: string) => {
    console.log('submitEdit', id);
    setOpenEditModal(false);
    selectGroup(id);
    mutate();
  };

  const submitDelete = (id: string) => {
    console.log('submitDelete', id);
    setOpenDeleteModal(false);
    selectGroup();
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
    selectGroup();
  };

  const closeDeleteModal = () => {
    console.log('closeDelete');
    setOpenDeleteModal(false);
    selectGroup();
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '250px' }}><span className={classes.heading}>Name</span></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '250px' }}><span className={classes.heading}>Description</span></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '180px' }}><span className={classes.heading}>Updated</span></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '100px' }}><span className={classes.heading}>Status</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '116px' }}>
              {canCreateGroup(currentUser) && (<Tooltip title="create new group"><IconButton color="primary" onClick={() => handleAddNew()}><AddIcon /></IconButton></Tooltip>)}
              {isLoading && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoading && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((row: any) => (
            <TableRow component="tr" key={row.groupId} onClick={() => selectGroup(row.groupId)} className={clsx(classes.row, { [classes.selected]: (selectedGroup?.groupId === row.groupId) })}>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '250px' }}><b>{row.name}</b></TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '250px' }}>{row.description}</TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '180px' }}>{format(new Date(row.updatedAt), 'dd-MMM-yyyy HH:mm')}</TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '100px' }}>{row.status ? 'active' : 'disabled'}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '116px' }}>
                {canEditGroup(currentUser) && (<Tooltip title="edit"><IconButton color="primary" onClick={() => handleOpenEditModal(row.groupId)}><EditIcon /></IconButton></Tooltip>)}
                {canDeleteGroup(currentUser) && (<Tooltip title="delete"><IconButton color="primary" onClick={() => handleOpenDeleteModal(row.groupId)}><DeleteIcon /></IconButton></Tooltip>)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container justifyContent='flex-end'>
        {canCreateGroup(currentUser) && (<Grid item><Button style={{ marginRight: '16px' }} size='small' onClick={() => handleAddNew()}>create group</Button></Grid>)}
        {canCreateUser(currentUser) && (<Grid item><Button style={{ marginRight: '16px' }} size='small' onClick={() => props.handleCreateUser()}>create user</Button></Grid>)}
        <Grid item><Button style={{ marginRight: '16px' }} size='small' onClick={() => selectGroup()}>show all</Button></Grid>
      </Grid>
      <EditGroupModal selectedGroup={selectedGroup} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <DeleteGroupModal selectedGroup={selectedGroup} open={openDeleteModal} closeModal={closeDeleteModal} handleSubmit={submitDelete} />
    </>
  );
};
