import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../hooks/context/useAuthStore";

import { Holding } from '../../shared/UI/Holding';

export const Home = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      navigate('/overview');
    } else {
      navigate('/logout');
    }
  }, [isAuthorized, navigate]);

  return (
    <Holding />
  );
};
