import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

import { useWishStore } from '../../hooks/context/useWishStore';
import { useAuthStore } from '../../hooks/context/useAuthStore';
import { canCreateWish } from '../../helpers/security';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { GroupTable } from '../../shared/Table/GroupTable';
import { WishTable } from './components/WishTable';
import { WishDetails } from '../../shared/Wish/WishDetails';

import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FlareIcon from '@mui/icons-material/Flare';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginBottom: theme.spacing(3)
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  paper2: {
    padding: theme.spacing(1, 2, 2, 2),
    marginTop: theme.spacing(3),
  },
  titles: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button1: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button2: {
    marginTop: theme.spacing(1),
  },
  scroll: {
    overflowX: 'scroll',
    scrollbarWidth: 'thin'
  }
}));

export const Wishes = () => {
  const { currentUser, isAuthorized } = useAuthStore();
  const { dataGroupList, dataWishList, selectedGroup, selectGroup, selectedWish, selectWish, isLoading, mutate, updatedAt, handleCreate, handleEdit, handleDelete, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handlePanel } = useWishStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();

  const [filterAdvisorUserId, setFilterAdvisorUserId] = useState('');
  const [filterState, setFilterState] = useState('');

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  useEffect(() => {
    if (location?.hash) {
      console.log('hash', location?.hash);
      if (location.hash.indexOf('#') === 0) {
        const filters = new URLSearchParams(location.hash.substring(1));
        setFilterAdvisorUserId(filters.get('advisorUserId') || '');
        setFilterState(filters.get('state') || '');
      }
    }
  }, [location]);

  const wishes = useMemo(() => {
    if (!selectedGroup) return dataWishList;
    return dataWishList?.filter((x: any) => (x.groupId === selectedGroup.groupId));
  }, [selectedGroup, dataWishList]);

  const handleWish = (id: string) => {
    console.log('handleWish', id);
    selectWish(id);
    if (selectedWish) navigate(`/wish/${selectedWish.groupId}/${selectedWish.wishId}`);
  }

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.grid}>
        <Grid item>
          <div className={classes.titles}>
            <h1 className={classes.title}>Wishes</h1>
            <h4 className={classes.subtitle}><span>Create</span> &amp; <span>Update</span> Leather Wishes</h4>
          </div>
        </Grid>
        {canCreateWish(currentUser) && (
          <Grid item>
            <Button size="small" variant="contained" color="primary" disableElevation endIcon={<FlareIcon />} className={classes.button1} onClick={() => handleCreate('appointment')}>Create Appointment</Button>
            <Button size="small" variant="contained" color="primary" disableElevation endIcon={<AutoAwesomeIcon />} className={classes.button2} onClick={() => handleCreate('wish')}>Create Wish</Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
          <Paper className={classes.paper}>
            <GroupTable data={dataGroupList} isLoading={isLoading} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} />
          </Paper>
          {selectedWish?.wishId && !isMobile && (
            <Paper className={classes.paper2}>
              <WishDetails
                isLoading={isLoading}
                mutate={mutate}
                updatedAt={updatedAt}
                selectedWish={selectedWish}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleSubmit={handleSubmit}
                handleReview={handleReview}
                handleApprove={handleApprove}
                handleSchedule={handleSchedule}
                handleDeliver={handleDeliver}
                handleCancel={handleCancel}
                handleState={handleState}
                handlePanel={handlePanel}
                handleWish={handleWish}
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
          <Paper className={classes.paper}>
            <div  className={classes.scroll}>
              <WishTable
                data={wishes}
                isLoading={isLoading}
                mutate={mutate}
                selectWish={selectWish}
                selectedWish={selectedWish}
                selectedGroup={selectedGroup}
                filterAdvisorUserId={filterAdvisorUserId}
                filterState={filterState}
                handlePanel={handlePanel}
                handleWish={handleWish}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
      {selectedWish?.wishId && isMobile && (
        <Paper className={classes.paper2}>
          <WishDetails
            selectedWish={selectedWish}
            isLoading={isLoading}
            mutate={mutate}
            updatedAt={updatedAt}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleSubmit={handleSubmit}
            handleReview={handleReview}
            handleApprove={handleApprove}
            handleSchedule={handleSchedule}
            handleDeliver={handleDeliver}
            handleCancel={handleCancel}
            handleState={handleState}
            handlePanel={handlePanel}
            handleWish={handleWish}
          />
        </Paper>
      )}
    </AppLayout>
  );
};
