import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthStore } from '../../hooks/context/useAuthStore';
import { useWishStore } from '../../hooks/context/useWishStore';
import { canCreateWish } from '../../helpers/security';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { GroupTable } from '../../shared/Table/GroupTable';
import { Online } from './components/Online';
import { Totals } from './components/Totals';
import { LineChart } from './charts/LineChart';
import { PieChart } from './charts/PieChart';
import { BarChart } from './charts/BarChart';
import { SplineChart } from './charts/SplineChart';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FlareIcon from '@mui/icons-material/Flare';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  paper2: {
    padding: theme.spacing(1, 2, 2, 2),
    marginTop: theme.spacing(3),
  },
  titles: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button1: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button2: {
    marginTop: theme.spacing(1),
  }
}));

export const Activity = () => {
  const { currentUser, isAuthorized } = useAuthStore();
  const { dataGroupList, selectedGroup, selectGroup, isLoading, mutate, handleCreate, } = useWishStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);


  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.grid}>
        <Grid item>
          <div className={classes.titles}>
            <h1 className={classes.title}>Activity</h1>
            <h4 className={classes.subtitle}><span>View</span> Reports &amp; Activity</h4>
          </div>
        </Grid>
        {canCreateWish(currentUser) && (
          <Grid item>
            <Button size="small" variant="contained" color="primary" disableElevation endIcon={<FlareIcon />} className={classes.button1} onClick={() => handleCreate('appointment')}>Create Appointment</Button>
            <Button size="small" variant="contained" color="primary" disableElevation endIcon={<AutoAwesomeIcon />} className={classes.button2} onClick={() => handleCreate('wish')}>Create Wish</Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
          <Paper className={classes.paper}>
            <GroupTable data={dataGroupList} isLoading={isLoading} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} />
          </Paper>
          <Online />
          <Totals />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <LineChart />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Paper className={classes.paper}>
                <PieChart />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Paper className={classes.paper}>
                <BarChart />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <SplineChart />
              </Paper>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </AppLayout>
  );
};
