import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import { useAuthStore } from "../../hooks/context/useAuthStore";

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { Avatar } from '../../shared/Picture/Avatar';
import { UserButtons } from './components/UserButtons';
import { Groups } from './components/Groups';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';


const useStyles = makeStyles()((theme) => ({
  grid: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  paper2: {
    padding: theme.spacing(1, 2, 2, 2),
    marginTop: theme.spacing(3),
  },
  titles: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
  verified: {
    color: theme.palette.success.dark,
  },
  picture: {
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
  },
}));

export const Settings = () => {
  const { currentUser, isAuthorized, isLoading, mutate } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.grid}>
        <Grid item>
          <div className={classes.titles}>
            <h1 className={classes.title}>Settings</h1>
            <h4 className={classes.subtitle}>View <span>Your</span> Profile & Settings</h4>
          </div>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-start" spacing={3}>
        <Grid item xl={2} lg={4} md={5} sm={12} xs={12}><Paper className={classes.paper}><Avatar size="large" picture={currentUser.picture} /></Paper></Grid>
        <Grid item xl={5} lg={8} md={7} sm={12} xs={12}>

          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={11} sm={11} md={11} lg={7}>
                {currentUser && (
                  <Grid container alignItems="flex-end" spacing={1} style={{ marginBottom: '16px' }}>
                    <Grid item xs={4} className={classes.itemTitle} style={{ marginBottom: '3px' }}>username: </Grid><Grid item xs={8} className={classes.itemValue} style={{ fontSize: '1.2rem', fontWeight: 500 }}>{currentUser.username}</Grid>
                    <Grid item xs={4} className={classes.itemTitle}>name: </Grid><Grid item xs={8} className={classes.itemValue}>{currentUser.name}</Grid>
                    <Grid item xs={4} className={classes.itemTitle}>email: </Grid><Grid item xs={8} className={classes.itemValue}>{currentUser.email}</Grid>
                    <Grid item xs={4} className={classes.itemTitle}>updated: </Grid><Grid item xs={8} className={classes.itemValue}>{currentUser.updatedAt ? format(new Date(currentUser.updatedAt), 'dd-MMM-yyyy h:mm a') : ''}</Grid>
                    <Grid item xs={4} className={classes.itemTitle}>last Login: </Grid><Grid item xs={8} className={classes.itemValue}>{currentUser.loginAt ? format(new Date(currentUser.loginAt), 'dd-MMM-yyyy h:mm a') : ''}</Grid>
                    <Grid item xs={4} className={classes.itemTitle}>logins: </Grid><Grid item xs={8} className={classes.itemValue}>{currentUser.numLogins}</Grid>
                    <Grid item xs={4} className={classes.itemTitle} style={{ marginBottom: '6px' }}>verified: </Grid><Grid item xs={8} className={classes.itemValue}>
                      {(currentUser.isEmailVerified) && (
                        <div className={classes.verified}><CheckCircleOutlineRoundedIcon /></div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                {isLoading && (
                  <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={18} /></IconButton></Tooltip>
                )}
                {!isLoading && (
                  <Tooltip title="refresh"><IconButton color="primary" size="small" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <UserButtons data={currentUser} isLoading={isLoading} mutate={mutate} />
              </Grid>
            </Grid>
          </Paper>

        </Grid>
        <Grid item xl={5} lg={12} md={12} sm={12} xs={12}>
          <Groups selectedUser={currentUser} />
        </Grid>

      </Grid>
    </AppLayout>
  );
};