import { useState } from "react";
import { formatDistance } from 'date-fns';

import { useAuthStore } from "../../../hooks/context/useAuthStore";
import { useInterval } from "../../../hooks/utils/useInterval";
import { online } from "../../../services/auth";

import { Avatar } from '../../../shared/Picture/Avatar';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
    marginTop: theme.spacing(3)
  },
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#333042',
  },
  duration: {
    marginTop: theme.spacing(1),
    fontSize: '0.8em',
    fontWeight: 300,
    color: '#333042',
  },
  picture: {
    width: '40px',
    height: '40px',
    maxWidth: '40px',
    marginRight: theme.spacing(2)
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  skeleton: {
    height: '150px',
    marginBottom: theme.spacing(3),
  },
  you: {
    fontWeight: 300
  }
}));

export const Online = () => {
  const { isAuthorized } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<any[]>();

  const handleRefresh = () => {
    if (!isLoading) {
      setIsLoading(true);
      online()
        .then((data: any) => setUsers(data))
        .catch((e: any) => console.log(e))
        .finally(() => setIsLoading(false));
    }
  };
  useInterval(() => isAuthorized && handleRefresh(), 60000, true);

  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Who's Online</span></TableCell>
            <TableCell className={classes.cell} align="right">
              {isLoading && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoading && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => handleRefresh()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user: any, index: number) => (
            <TableRow component="tr" key={user.username}>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '140px'}}>
                <Grid container>
                  <Grid item className={classes.picture}>
                    <Avatar size="normal" picture={user.picture} />
                  </Grid>
                  <Grid item>
                    <h3 className={classes.subtitle}>{user.username}</h3>
                    {user.isCurrentUser && (
                      <h6><span className={classes.you}>( you )</span></h6>
                    )}
                    {!user.isCurrentUser && (
                      <h6><span className={classes.you}>Manager</span></h6>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.cell} align="right">
                <h3 className={classes.duration}>{formatDistance(new Date(user.updatedAt), new Date())}</h3>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}