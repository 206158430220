import { AppSkeleton } from "../../../shared/UI/AppSkeleton";
import { Avatar } from '../../../shared/Picture/Avatar';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(1.5),
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#333042',
  },
  duration: {
    marginTop: theme.spacing(0.5),
    fontWeight: 300,
    color: '#333042',
  },
  picture: {
    width: '40px',
    height: '40px',
    maxWidth: '40px',
    marginRight: theme.spacing(2)
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  skeleton: {
    height: '150px',
    marginBottom: theme.spacing(3),
  },
  you: {
    fontWeight: 300
  }
}));

const users = [
 {  username: 'Inara', total: 35, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'Michael', total: 28, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'Susan', total: 23, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'David', total: 18, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'Amelia', total: 14, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'Isabella', total: 14, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'Paul', total: 13, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'Trevor', total: 9, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'Andrew', total: 3, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
 {  username: 'Emily', total: 2, picture: 'https://assets.cloud.leatherwish.com/profile/default.png' },
];

export const Totals = () => {
  const { classes } = useStyles();
  const isLoading = false;
  const handleRefresh = () => {};

  return (
    <>
      {(!users) && (
        <AppSkeleton className={classes.skeleton} />
      )}
      {(users) && (
        <Paper className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell} align="left"><span className={classes.heading}>Open Wishes</span></TableCell>
                <TableCell className={classes.cell} align="right">
                  {isLoading && (
                    <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
                  )}
                  {!isLoading && (
                    <Tooltip title="refresh"><IconButton color="primary" onClick={() => handleRefresh()}><RefreshIcon /></IconButton></Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user: any, index: number) => (
                <TableRow component="tr" key={user.username}>
                  <TableCell className={classes.cell} align="left">
                    <Grid container>
                      <Grid item className={classes.picture}>
                        <Avatar size="normal" picture={user.picture} />
                      </Grid>
                      <Grid item>
                        <h3 className={classes.subtitle}>{user.username}</h3>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                    <h3 className={classes.duration}>{user.total}</h3>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </>
  );
}