
import { makeStyles } from 'tss-react/mui';

interface IAvatar {
  picture: string;
  size: string;
}

const useStyles = makeStyles<IAvatar>()((theme: any, props: IAvatar) => ({
  container: {
    display: 'block',
    paddingBottom: '100%',
    borderRadius: props.size === 'large' ? theme.spacing(2) : props.size === 'normal' ? theme.spacing(1) : theme.spacing(0.5),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundColor: '#ccc',
    backgroundImage: `url('${props.picture}')`,
  }
}));

export const Avatar = (props: IAvatar) => {
  const { classes } = useStyles(props);
  return (
    <div className={classes.container}></div>
  );
}