import useSWR from 'swr';

import { useAuthStore } from "../context/useAuthStore";
import { listBudget } from "../../services/budget";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

const apiBudgetList = ([_, groupId, year]: any[]) => listBudget(groupId, year);

export const useApiBudgetList = (groupId: string, year: number) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && groupId && year ? ['useApiBudgetList', groupId, year] : null, apiBudgetList, options);
  return {
    data: data?.status ? data.data : [],
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};

