import { ECategory } from '../../../services/types';

import { WishTable } from './WishTable';

import { makeStyles } from 'tss-react/mui';
import { Paper, Box } from '@mui/material';
import { Tabs, Tab } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    padding: 0,
    marginBottom: theme.spacing(3)
  },
  scroll: {
    overflowX: 'scroll',
    scrollbarWidth: 'thin'
  }
}));

const monthIsEqual = (year: number, month: number, ds2: string) => {
  if (year > 2000 && month >= 0 && ds2) {
    const dt1 = new Date(year, month, 1, 0, 0, 0, 0);
    const dt2 = new Date(year, month + 1, 1, 0, 0, 0, 0);
    const ds2t = new Date(ds2).getTime();
    return (dt1.getTime() <= ds2t && ds2t < dt2.getTime());
  }
  return false;
}

interface TabPanelProps {
  children?: React.ReactNode;
  key: string;
  value: number;
  index: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`schedule-panel-${index}`}
      {...other}
    >
      {value === index && (<Box>{children}</Box>)}
    </div>
  );
}

export const PlanningTabs = (props: any) => {
  const { value, setValue, cegidTotal, setCegidTotal, year, month, data, isLoading, mutate, selectWish, selectedWish, selectedGroup, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handleEdit, handleDelete, handlePanel } = props;


  const { classes } = useStyles();
  return (
    <>
      <Paper className={classes.root}>
        <Tabs value={value} onChange={(e: any, val: number) => setValue(val)}>
          {(Object.keys(ECategory) as Array<keyof typeof ECategory>).map((key, index) => (
            <Tab key={key} id={`schedule-tab-${key}`} label={`${ECategory[key]}`} />
          ))}
        </Tabs>
      </Paper>
      {(Object.keys(ECategory) as Array<keyof typeof ECategory>).map((key, index) => (
        <CustomTabPanel key={key} value={value} index={index}>
          <Paper className={classes.paper}>
            <div className={classes.scroll}>
              <WishTable
                cegidTotal={cegidTotal}
                setCegidTotal={setCegidTotal}
                year={year}
                month={month}
                data={data.filter((x: any) => x.productCategory === key).filter((x: any) => monthIsEqual(year, month, x.scheduleDate))}
                isLoading={isLoading}
                mutate={mutate}
                selectWish={selectWish}
                selectedWish={selectedWish}
                selectedGroup={selectedGroup}
                handleSubmit={handleSubmit}
                handleReview={handleReview}
                handleApprove={handleApprove}
                handleSchedule={handleSchedule}
                handleDeliver={handleDeliver}
                handleCancel={handleCancel}
                handleState={handleState}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handlePanel={handlePanel}
              />
            </div>
          </Paper>
        </CustomTabPanel>
      ))}
    </>
  );
};