import clsx from 'clsx';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    fontWeight: 500,
    color: '#333'
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    fontSize: '0.8em',
    fontWeight: 300,
  },
}));

export const WishTable = (props: any) => {
  const { title, subtitle, data, selectWish, selectedWish, handleMore } = props;

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left">
              <div className={classes.title}>{title}</div>
              <div className={classes.subtitle}>{subtitle}</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.slice(0, 5).map((row: any) => (
            <TableRow component="tr" key={row.wishId} onClick={() => selectWish(row.wishId)} className={clsx(classes.row, { [classes.selected]: (selectedWish?.wishId === row.wishId) })}>
              <TableCell className={classes.cell} align="left"><b>{row.name}</b></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button color="primary" size="small" onClick={() => handleMore()}>more</Button>
        </Grid>
      </Grid>
    </>
  );
};
