import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ulid } from "ulid";

import { useQuery } from "../../hooks/utils/useQuery";
import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useEffectOnce } from "../../hooks/utils/useEffectOnce";
import { exchangeCode, getLoginLink } from "../../services/auth";

import { Typography, CircularProgress, Theme, Grid } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

import { Toaster } from "../../shared/Alert/Toaster";
import { AppButton } from "../../shared/UI/AppButton";
import appLogo from "../../assets/logos/leatherwish-logo.svg";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    zIndex: 2000,
    position: 'relative',
  },
  logoTitle: {
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: 'Roboto Slab',
    fontSize: '2rem',
    fontWeight: 500,
    '& span': {
      color: 'rgba(255, 255, 255, 0.7)',
      fontFamily: 'Roboto',
      fontSize: '2rem',
      fontWeight: 300,
    }
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      height: 'auto',
      width: '84px',
    },
  },
  progress: {
    width: 130,
    marginLeft: 30,
    marginRight: 30,
    textAlign: 'center'
  },
  btnLogin: {
    width: 130,
    marginLeft: 30,
    marginRight: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
}),
);

export const SignIn = () => {
  const { currentUser, isAuthorized, setIdToken, setAccessToken, setRefreshToken } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [loginAt, setLoginAt] = useState('');
  const query = useQuery();

  const navigate = useNavigate();
  // const navigate = (url: string) => {
  //   console.log('nav: ', url )
  // }

  const newUniqueId = () => {
    const uid = ulid();
    localStorage.setItem("uniqueId", uid);
    return uid;
  };

  const handleLogin = () => {
    setIsLoading(true);
    const uid = newUniqueId();
    getLoginLink(uid).then(link => {
      // setIsLoading(false);
      window.location.href = link;
    })
      .catch((e: any) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  useEffectOnce(() => {
    if (isAuthorized) {
      setIsLoading(false);
      navigate("/");
    } else {
      const code = query.get("code");
      const state = query.get("state");
      const uniqueId = localStorage.getItem("uniqueId");
      if (code && (state === uniqueId)) {
        setIsLoading(true);
        const finalCode = Array.isArray(code) ? code[0] : code;
        // console.log('finalCode', finalCode);
        exchangeCode(finalCode).then((data) => {
          // console.log('exchangeCode', data);
          if (data) {
            setIdToken(data.id_token);
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            setLoginAt(new Date().toISOString());
          }
        }).catch((e: any) => {
          setIsLoading(false);
          console.log('exchangeCode', e);
        });
      } else {
        setIsLoading(false);
      }
    }
  });

  useEffect(() => {
    if (currentUser?.userId && isAuthorized && loginAt) {
      setIsLoading(false);
      navigate("/overview");
    }
  }, [currentUser, isAuthorized, loginAt, navigate])

  const error = query.get('error');
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <div className={classes.logoWrapper}>
            <div><img src={appLogo} alt="" /></div>
            <Typography variant="h1" className={classes.logoTitle}>Leather<span>Wish</span></Typography>
          </div>
        </Grid>
        <Grid item>
          {isLoading && (
            <div className={classes.progress}>
              <CircularProgress color="primary" size={35} />
            </div>
          )}
          {!isLoading && (
            <AppButton onClick={handleLogin} loading={isLoading} size="medium" className={classes.btnLogin}>Login</AppButton>
          )}
        </Grid>
      </Grid>
      {(error === '401') && (
        <Toaster severity="error" message="Your Session has timed out. Please login again." />
      )}
      {(error === '403') && (
        <Toaster severity="error" message="Your Login Account is not authorized to use this application. Please login again, or contact support." />
      )}
    </div>
  );
}