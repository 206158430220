import { IUser } from '../context/AuthProvider';

export const canViewOverview = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isReviewer || user.isMetier || user.isAdvisor || user.isCashier);
}

export const canViewWishes = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isReviewer || user.isMetier || user.isAdvisor || user.isCashier);
}

export const canViewSchedule = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isReviewer || user.isMetier || user.isAdvisor || user.isCashier);
}

export const canViewPlanning = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canViewActivity = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canViewProducts = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canViewUsers = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canViewSettings = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isReviewer || user.isMetier || user.isAdvisor || user.isCashier);
}

export const canCreateWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isReviewer || user.isMetier || user.isAdvisor);
}

export const canEditWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canDeleteWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canSubmitWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isMetier);
}

export const canReviewWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isReviewer);
}

export const canApproveWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canScheduleWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canCancelWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canDeliverWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isMetier || user.isAdvisor || user.isCashier);
}

export const canStateWish = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canViewBudget = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager || user.isReviewer || user.isMetier || user.isAdvisor || user.isCashier);
}

export const canEditBudget = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canCreateProduct = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canEditProduct = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canDeleteProduct = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canCreateGroup = (user: IUser) => {
  return (user.isSuperAdmin);
}

export const canEditGroup = (user: IUser) => {
  return (user.isAdministrator);
}

export const canDeleteGroup = (user: IUser) => {
  return (user.isSuperAdmin);
}

export const canCreateUser = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canEditUser = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canBlockUser = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canPasswordUser = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}

export const canDeleteUser = (user: IUser) => {
  return (user.isAdministrator || user.isDirector || user.isManager);
}
