import { useQuery } from "../../hooks/utils/useQuery";
import { useAuthStore } from "../../hooks/context/useAuthStore";

import { Holding } from '../../shared/UI/Holding';

export const Logout = () => {
  const { logout } = useAuthStore();
  const query = useQuery();

  var redirectTo = query.get('redirectTo') || '';
  const error = query.get('error') || '';
  if (redirectTo.indexOf('logout') >= 0) redirectTo = '';
  logout(redirectTo, error);

  return (
    <Holding />
  );
};
