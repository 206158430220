import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApiGroupUserList } from '../../hooks/admin/useApiGroupUserList';
import { useApiUserList } from '../../hooks/admin/useApiUserList';
import { useAuthStore } from '../../hooks/context/useAuthStore';
import { useWishStore } from '../../hooks/context/useWishStore';
import { canCreateUser } from '../../helpers/security';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { GroupTable } from './components/GroupTable';
import { UserTable } from './components/UserTable';
import { CreateUserModal } from './components/CreateUserModal';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  paper2: {
    padding: theme.spacing(1, 2, 2, 2),
    marginTop: theme.spacing(3),
  },
  titles: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button2: {
    marginTop: theme.spacing(1),
  },
  scroll: {
    overflowX: 'scroll',
    scrollbarWidth: 'thin'
  }
}));

export const Users = () => {
  const { currentUser, isAuthorized } = useAuthStore();
  const { dataGroupList, selectedGroup, selectGroup, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useWishStore();

  const [selectedUser, setSelectedUser] = useState<any>();
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);

  const { data: dataUserList, isLoading: isLoadingUserList, mutate: mutateUserList } = useApiUserList();
  const { data: dataGroupUsers, isLoading: isLoadingGroupUsers, mutate: mutateGroupUsers } = useApiGroupUserList();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const selectUser = (id?: string) => {
    console.log('selectUser', id);
    const user = dataUserList?.find((x: any) => x.userId === id);
    setSelectedUser(user);
  }

  const mutate = () => {
    mutateGroupList();
    mutateUserList();
    mutateGroupUsers();
  }

  const handleOpenCreateUserModal = () => {
    console.log('handleOpenCreateUserModal');
    setOpenCreateUserModal(true);
  };

  const submitCreateUser = (id: string) => {
    console.log('submitCreateUser', id);
    setOpenCreateUserModal(false);
    mutate();
    navigate(`/user/${id}`);
  };

  const closeCreateUserModal = () => {
    console.log('closeCreateUser');
    setOpenCreateUserModal(false);
  };

  const isLoading = useMemo(() => (isLoadingGroupUsers || isLoadingUserList), [isLoadingGroupUsers, isLoadingUserList]);

  const users = useMemo(() => {
    if (!selectedGroup) return dataUserList;
    const userGroups = dataGroupUsers?.filter((x: any) => x.groupId === selectedGroup.groupId);
    return dataUserList?.filter((x: any) => userGroups?.find((y: any) => y.userId === x.userId))
  }, [selectedGroup, dataGroupUsers, dataUserList]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.grid}>
        <Grid item>
          <div className={classes.titles}>
            <h1 className={classes.title}>Account Manager</h1>
            <h4 className={classes.subtitle}><span>Add</span> &amp; <span>Remove</span> Users Profiles</h4>
          </div>
        </Grid>
        {( canCreateUser(currentUser) && 
        <Grid item>
          <Button size="small" variant="contained" color="primary" disableElevation endIcon={<PersonAddAltIcon />} className={classes.button2} onClick={() => handleOpenCreateUserModal()}>Add New User</Button>
        </Grid>
        )}
      </Grid>
      <Paper className={classes.paper}>
        <div className={classes.scroll}>
          <GroupTable data={dataGroupList} isLoading={isLoadingGroupList || isLoadingGroupUsers} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} handleCreateUser={handleOpenCreateUserModal} />
        </div>
      </Paper>
      <Paper className={classes.paper2}>
        <div className={classes.scroll}>
          <UserTable data={users} isLoading={isLoading} mutate={mutate} selectUser={selectUser} selectedUser={selectedUser} selectedGroup={selectedGroup} handleCreateUser={handleOpenCreateUserModal} />
        </div>
      </Paper>
      <CreateUserModal selectedGroup={selectedGroup} open={openCreateUserModal} closeModal={closeCreateUserModal} handleSubmit={submitCreateUser} />
    </AppLayout>
  );
};
