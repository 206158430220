import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  year: {
    fontSize: '0.8em',
    textAlign: 'center',
    cursor: 'pointer'
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    padding: theme.spacing(1, 0),
    borderRadius: theme.spacing(2),
  },
  unselected: {
    fontWeight: 500,
    padding: theme.spacing(1, 0)
  }
}));

export const Years = (props: any) => {
  const { year, setYear } = props;

  const now = new Date();
  const yyNow = now.getFullYear();
  const yyStart = yyNow - 4;
  const yyEnd = yyNow + 4;
  const years = [] as number[];
  for (var i = yyStart; i <= yyEnd; i++) {
    years.push(i);
  }

  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        {years.map((yy: number) => (
          <Grid item xs={4} className={classes.year} key={yy} onClick={() => setYear(yy)}>
            {yy === year && (<div className={classes.selected}>{yy}</div>)}
            {yy !== year && (<div className={classes.unselected}>{yy}</div>)}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};