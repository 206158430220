import { useState } from 'react';

import { ECategory } from '../../../services/types';

import { Day } from './Day';
import { WishHeader } from './WishHeader';

import { makeStyles } from 'tss-react/mui';
import { Paper, Box } from '@mui/material';
import { Tabs, Tab } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    padding: 0,
  },
  scroll: {
    overflowX: 'scroll',
    scrollbarWidth: 'thin'
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  key: string;
  value: number;
  index: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`schedule-panel-${index}`}
      {...other}
    >
      {value === index && (<Box>{children}</Box>)}
    </div>
  );
}

export const ScheduleTabs = (props: any) => {
  const { year, month, days, data, isLoading, mutate, selectWish, selectedWish, selectedGroup, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handleEdit, handleDelete, handlePanel } = props;

  const [value, setValue] = useState(0);
  const { classes } = useStyles();
  return (
    <>
      <Paper className={classes.root}>
        <Tabs value={value} onChange={(e: any, val: number) => setValue(val)}>
          {(Object.keys(ECategory) as Array<keyof typeof ECategory>).map((key, index) => (
            <Tab key={key} id={`schedule-tab-${key}`} label={`${ECategory[key]}`} />
          ))}
        </Tabs>
      </Paper>
      {(Object.keys(ECategory) as Array<keyof typeof ECategory>).map((key, index) => (
        <CustomTabPanel key={key} value={value} index={index}>

          <Paper className={classes.paper}>
            <div className={classes.scroll}>
              <WishHeader
                isLoading={isLoading}
                mutate={mutate}
              />
            </div>
          </Paper>

          {days?.map((dd: string) => (
            <Day
              key={dd}
              year={year}
              month={month}
              day={dd}
              data={data?.filter((x: any) => x.productCategory === key)}
              isLoading={isLoading}
              mutate={mutate}
              selectWish={selectWish}
              selectedWish={selectedWish}
              selectedGroup={selectedGroup}
              handleSubmit={handleSubmit}
              handleReview={handleReview}
              handleApprove={handleApprove}
              handleSchedule={handleSchedule}
              handleDeliver={handleDeliver}
              handleState={handleState}
              handleCancel={handleCancel}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handlePanel={handlePanel}
            />
          ))}
        </CustomTabPanel>
      ))}
    </>
  );
};