import { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import data from './usdeur.json';

export const LineChart = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options = {
    chart: {
      zooming: {
        type: 'x'
      }
    },
    title: {
      text: 'DEMO Sales Revenue'
    },
    subtitle: 'USD to EUR exchange rate over time',
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      title: {
        text: 'Revenue'
      }
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      area: {
        marker: {
          radius: 2
        },
        lineWidth: 1,
        color: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, 'rgb(199, 113, 243)'],
            [0.7, 'rgb(76, 175, 254)']
          ]
        },
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },

    series: [{
      type: 'area',
      name: 'USD to EUR',
      data: data
    }]
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
};
