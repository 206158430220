import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../hooks/context/useAuthStore';
import { useApiProductList } from '../../hooks/api/useApiProductList';
import { canCreateProduct } from '../../helpers/security';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { ProductTable } from './components/ProductTable';
import { EditProductModal } from './components/EditProductModal';
import { DeleteProductModal } from './components/DeleteProductModal';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginBottom: theme.spacing(3)
  },
  titles: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button1: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button2: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1, 3, 3, 3),
    marginBottom: theme.spacing(3),
  },
  scroll: {
    overflowX: 'scroll',
    scrollbarWidth: 'thin'
  }
}));

export const Products = () => {
  const { currentUser, isAuthorized } = useAuthStore();

  const { data, isLoading, mutate } = useApiProductList();
  const [selectedProduct, setSelectedProduct] = useState<any>();

  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openDeleteProductModal, setOpenDeleteProductModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const selectProduct = (id?: string) => {
    console.log('selectProduct', id);
    const product = data?.find((x: any) => x.productId === id);
    setSelectedProduct(product);
  }

  const handleCreateProduct = () => {
    console.log('handleCreateProduct');
    selectProduct();
    setOpenEditProductModal(true);
  }

  const handleOpenEditProductModal = (id: string) => {
    console.log('handleOpenEditProductModal', id);
    selectProduct(id);
    setOpenEditProductModal(true);
  };

  const submitEditProduct = (productId: string) => {
    console.log('submitEditProduct', productId);
    selectProduct();
    setOpenEditProductModal(false);
    mutate();
  };

  const closeEditProductModal = () => {
    console.log('closeEditProduct');
    selectProduct();
    setOpenEditProductModal(false);
  };

  const handleOpenDeleteProductModal = (id: string) => {
    console.log('handleOpenDeleteProductModal', id);
    selectProduct(id);
    setOpenDeleteProductModal(true);
  };

  const submitDeleteProduct = (productId: string) => {
    console.log('submitDeleteProduct', productId);
    selectProduct();
    setOpenDeleteProductModal(false);
    mutate();
  };

  const closeDeleteProductModal = () => {
    console.log('closeDeleteProduct');
    selectProduct();
    setOpenDeleteProductModal(false);
  };

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.grid}>
        <Grid item>
          <div className={classes.titles}>
        <h1 className={classes.title}>Products</h1>
        <h4 className={classes.subtitle}><span>Add</span> &amp; <span>Update</span> the Leather Products</h4>
      </div>
      </Grid>
        {canCreateProduct(currentUser) && (
          <Grid item>
            <Button size="small" variant="contained" color="primary" disableElevation endIcon={<AddIcon />} className={classes.button2} onClick={() => handleCreateProduct()}>Add Product</Button>
          </Grid>
        )}
      </Grid>      
      <Paper className={classes.paper}>
        <div className={classes.scroll}>
          <ProductTable data={data} isLoading={isLoading} mutate={mutate} selectProduct={selectProduct} selectedProduct={selectedProduct} handleCreate={handleCreateProduct} handleEdit={handleOpenEditProductModal} handleDelete={handleOpenDeleteProductModal} />
        </div>
      </Paper>
      <EditProductModal selectedProduct={selectedProduct} open={openEditProductModal} closeModal={closeEditProductModal} handleSubmit={submitEditProduct} />
      <DeleteProductModal selectedProduct={selectedProduct} open={openDeleteProductModal} closeModal={closeDeleteProductModal} handleSubmit={submitDeleteProduct} />

    </AppLayout>
  );
};
