import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useWishStore } from '../../hooks/context/useWishStore';
import { useApiBudgetList } from '../../hooks/api/useApiBudgetList';
import { canCreateWish, canViewBudget } from '../../helpers/security';
import { EState, ECategory } from '../../services/types';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { WishDetails } from '../../shared/Wish/WishDetails';
import { WishBudgetPlanning } from '../../shared/Wish/WishBudgetPlanning';
import { GroupTable } from '../../shared/Table/GroupTable';
import { EditBudgetModal } from '../../shared/Modal/EditBudgetModal';

import { Years } from '../../shared/Card/Years';
import { Months } from '../../shared/Card/Months';
import { PlanningTabs } from './components/PlanningTabs';

import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FlareIcon from '@mui/icons-material/Flare';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginBottom: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  paper2: {
    padding: theme.spacing(1, 2, 2, 2),
    marginTop: theme.spacing(3),
  },
  titles: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button1: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button2: {
    marginTop: theme.spacing(1),
  }
}));

export const Planning = () => {
  const { currentUser, isAuthorized } = useAuthStore();
  const { dataGroupList, dataWishList, selectedGroup, selectGroup, selectedWish, selectWish, isLoading, mutate, updatedAt, handleCreate, handleEdit, handleDelete, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handlePanel } = useWishStore();
  const [cegidTotal, setCegidTotal] = useState(0);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const now = new Date();
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth());

  const { data: dataBudgetList, isLoading: isLoadingBudgetList, mutate: mutateBudgetList } = useApiBudgetList(selectedGroup?.groupId, year);
  const [openEditBudgetModal, setOpenEditBudgetModal] = useState(false);

  const selectedBudget = useMemo(() => {
    if (dataBudgetList && dataWishList) {
      const budget = dataBudgetList?.find((x: any) => Number(x.year) === Number(year) && Number(x.month) - 1 === Number(month) && x.status === true);
      if (budget) {
        const byr = parseInt(budget.year);
        const bmn = parseInt(budget.month) - 1;
        budget.kellyClassicSales = 0;
        budget.kellyExoticSales = 0;
        budget.birkinClassicSales = 0;
        budget.birkinExoticSales = 0;
        budget.kellyClassicCegid = 0;
        budget.kellyExoticCegid = 0;
        budget.birkinClassicCegid = 0;
        budget.birkinExoticCegid = 0;
        for (const wish of dataWishList?.filter((x: any) => (x.state === EState.scheduled || x.state === EState.completed))) {
          if (wish.scheduleDate) {
            const dt = new Date(wish.scheduleDate);
            const year = dt.getFullYear();
            const month = dt.getMonth();
            if (byr === year && bmn === month) {
              switch (wish.productCategory) {
                case "kelly": budget.kellyClassicSales += Number(wish.price); break;
                case "kellyx": budget.kellyExoticSales += Number(wish.price); break;
                case "birkin": budget.birkinClassicSales += Number(wish.price); break;
                case "birkinx": budget.birkinExoticSales += Number(wish.price); break;
                default: console.log('ERROR', 'no category', wish);
              }
            }
          }
        }
        switch ((Object.keys(ECategory) as Array<keyof typeof ECategory>)[value]) {
          case "kelly": budget.kellyClassicCegid = cegidTotal; break;
          case "kellyx": budget.kellyExoticCegid += cegidTotal; break;
          case "birkin": budget.birkinClassicCegid += cegidTotal; break;
          case "birkinx": budget.birkinExoticCegid += cegidTotal; break;
          default: console.log('ERROR', 'no category', value);
        }
        budget.kellySales = (budget.kellyClassicCegid || budget.kellyClassicSales) + (budget.kellyExoticCegid || budget.kellyExoticSales);
        budget.birkinSales = (budget.birkinClassicCegid || budget.birkinClassicSales) + (budget.birkinExoticCegid || budget.birkinExoticSales);
        budget.kellyAvailable = (budget.kelly || 0) - budget.kellySales;
        budget.birkinAvailable = (budget.birkin || 0) - budget.birkinSales;
        budget.totalBudget = (budget.kelly || 0) + (budget.birkin || 0);
        budget.totalSales = budget.kellySales + budget.birkinSales;
        budget.totalAvailable = budget.kellyAvailable + budget.birkinAvailable;
        // console.log('budget', budget);
        return budget;
      }
    }
  }, [dataBudgetList, dataWishList, year, month, cegidTotal, value]);

  const handleOpenEditBudgetModal = () => {
    console.log('handleOpenEditBudgetModal');
    setOpenEditBudgetModal(true);
  };

  const submitEditBudget = (groupId: string, year: string, month: string) => {
    console.log('submitEditBudget', groupId, year, month);
    setOpenEditBudgetModal(false);
    mutate();
  };

  const closeEditBudgetModal = () => {
    console.log('closeEditBudget');
    setOpenEditBudgetModal(false);
  };

  const handleWish = (id: string) => {
    console.log('handleWish', id);
    selectWish(id);
    if (selectedWish) navigate(`/wish/${selectedWish.groupId}/${selectedWish.wishId}`);
  }

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.grid}>
        <Grid item>
          <div className={classes.titles}>
            <h1 className={classes.title}>Planning</h1>
            <h4 className={classes.subtitle}>Leather Wish <span>Budgets</span> &amp; <span>Targets</span></h4>
          </div>
        </Grid>
        {canCreateWish(currentUser) && (
          <Grid item>
            <Button size="small" variant="contained" color="primary" disableElevation endIcon={<FlareIcon />} className={classes.button1} onClick={() => handleCreate('appointment')}>Create Appointment</Button>
            <Button size="small" variant="contained" color="primary" disableElevation endIcon={<AutoAwesomeIcon />} className={classes.button2} onClick={() => handleCreate('wish')}>Create Wish</Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
          <Paper className={classes.paper}>
            <GroupTable data={dataGroupList} isLoading={isLoading} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} />
          </Paper>
          <Years year={year} setYear={setYear} />
          <Months year={year} month={month} setMonth={setMonth} />
          {canViewBudget(currentUser) && (
            <Paper className={classes.paper2}>
              <WishBudgetPlanning
                selectedBudget={selectedBudget}
                year={year}
                month={month}
                data={dataBudgetList}
                isLoading={isLoadingBudgetList}
                mutate={mutateBudgetList}
                handleEdit={handleOpenEditBudgetModal}
              />
            </Paper>
          )}
          {selectedWish?.wishId && !isMobile && (
            <Paper className={classes.paper2}>
              <WishDetails
                isLoading={isLoading}
                mutate={mutate}
                updatedAt={updatedAt}
                selectedWish={selectedWish}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleSubmit={handleSubmit}
                handleReview={handleReview}
                handleApprove={handleApprove}
                handleSchedule={handleSchedule}
                handleDeliver={handleDeliver}
                handleCancel={handleCancel}
                handleState={handleState}
                handlePanel={handlePanel}
                handleWish={handleWish}
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
          {dataWishList && (
            <PlanningTabs
              value={value}
              setValue={setValue}
              cegidTotal={cegidTotal}
              setCegidTotal={setCegidTotal}
              year={year}
              month={month}
              data={dataWishList?.filter((x: any) => (x.state === EState.scheduled || x.state === EState.completed))}
              isLoading={isLoading}
              mutate={mutate}
              selectWish={selectWish}
              selectedWish={selectedWish}
              selectedGroup={selectedGroup}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleSubmit={handleSubmit}
              handleReview={handleReview}
              handleApprove={handleApprove}
              handleSchedule={handleSchedule}
              handleDeliver={handleDeliver}
              handleCancel={handleCancel}
              handleState={handleState}
              handlePanel={handlePanel}
            />
          )}
        </Grid>
      </Grid>
      {selectedWish?.wishId && isMobile && (
        <Paper className={classes.paper2}>
          <WishDetails
            selectedWish={selectedWish}
            isLoading={isLoading}
            mutate={mutate}
            updatedAt={updatedAt}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleSubmit={handleSubmit}
            handleReview={handleReview}
            handleApprove={handleApprove}
            handleSchedule={handleSchedule}
            handleDeliver={handleDeliver}
            handleCancel={handleCancel}
            handleState={handleState}
            handlePanel={handlePanel}
            handleWish={handleWish}
          />
        </Paper>
      )}
      <EditBudgetModal selectedGroup={selectedGroup} selectedBudget={selectedBudget} year={year} month={month} open={openEditBudgetModal} closeModal={closeEditBudgetModal} handleSubmit={submitEditBudget} />
    </AppLayout>
  );
};