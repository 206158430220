import { EState } from '../../services/types';
import { useAuthStore } from '../../hooks/context/useAuthStore';
import { canSubmitWish, canReviewWish, canScheduleWish, canApproveWish, canDeliverWish } from '../../helpers/security';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';

const useStyles: any = makeStyles()((theme) => ({
  button: {
    // margin: theme.spacing(3)
  },
}));

export const WishActionButton = (props: any) => {
  const { selectedWish, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver } = props;
  const { currentUser } = useAuthStore();
  const classes = useStyles();
  if (selectedWish) {
    switch (selectedWish.state) {
      case EState.created: return (<>{canSubmitWish(currentUser) && (<><Button variant='outlined' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleSubmit(selectedWish.wishId)}>Reject</Button> <Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleSubmit(selectedWish.wishId)}>Submit for Review</Button></>)}</>);
      case EState.submitted: return (<>{canReviewWish(currentUser) && (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleReview(selectedWish.wishId)}>Review</Button></>)}</>);
      case EState.rejected: return (<>{canReviewWish(currentUser) && (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleReview(selectedWish.wishId)}>Submit for Review</Button></>)}</>);
      case EState.reviewed: return (<>{canApproveWish(currentUser) && (<><Button variant='outlined' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleApprove(selectedWish.wishId)}>Deny</Button> <Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleApprove(selectedWish.wishId)}>Approve</Button></>)}</>);
      case EState.approved: return (<>{canScheduleWish(currentUser) && (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleSchedule(selectedWish.wishId)}>Schedule</Button></>)}</>);
      case EState.denied: return (<>{canApproveWish(currentUser) && (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleApprove(selectedWish.wishId)}>Approve</Button></>)}</>);
      case EState.scheduled: return (<>{canDeliverWish(currentUser) && (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleDeliver(selectedWish.wishId)}>Mark as Delivered</Button></>)}</>);
    }
  }
  return (<></>);
};
