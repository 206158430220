import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

import HamburgerIcon from "../../assets/nav/hamburger-icon.svg";
import appLogo from "../../assets/logos/leatherwish-logo.svg";

interface IAppBarLayoutStyles {
  drawerWidth: number;
}

const useStyles = makeStyles<IAppBarLayoutStyles>()((theme, props) =>
({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${props.drawerWidth}px)`,
      marginLeft: props.drawerWidth,
    },
    background: theme.palette.secondary.main,
  },
  menuButton: {
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoImgContainer: {
    '& img': {
      height: 40,
      width: 40,
    },
  },
  logoTitle: {
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: 'Roboto Slab',
    fontSize: '1rem',
    fontWeight: 500,
    '& span': {
      color: 'rgba(255, 255, 255, 0.7)',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontWeight: 300,
    }
  },
  toolbar: {
    position: 'relative',
  },
})
);

interface IAppBarLayout {
  drawerWidth: number;
  setMobileOpen: (value: boolean) => void;
  mobileOpen: boolean;
}

export function AppBarLayout({ drawerWidth, setMobileOpen, mobileOpen }: IAppBarLayout) {
  const { classes } = useStyles({ drawerWidth });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <img src={HamburgerIcon} alt="" />
          </IconButton>
          <div className={classes.logoWrapper}>
            <div className={classes.logoImgContainer}>
              <img src={appLogo} alt="" />
            </div>
            <Typography variant="h2" className={classes.logoTitle}>Leather<span>Wish</span></Typography>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
