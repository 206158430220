import { useAuthStore } from '../../hooks/context/useAuthStore';
import { canSubmitWish, canReviewWish, canApproveWish, canScheduleWish, canCancelWish, canDeliverWish, canStateWish, canEditWish, canDeleteWish } from '../../helpers/security';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingIcon from '@mui/icons-material/Pending';

export const WishButtons = (props: any) => {
  const { selectedWish, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handleEdit, handleDelete } = props;
  const { currentUser } = useAuthStore();
  return (
    <>
      {canEditWish(currentUser) && (<Tooltip title="edit"><IconButton color="primary" onClick={() => handleEdit(selectedWish.wishId)}><EditIcon /></IconButton></Tooltip>)}
      {canSubmitWish(currentUser) && (<Tooltip title="submit"><IconButton color="primary" onClick={() => handleSubmit(selectedWish.wishId)}><PublishIcon /></IconButton></Tooltip>)}
      {canReviewWish(currentUser) && (<Tooltip title="review"><IconButton color="primary" onClick={() => handleReview(selectedWish.wishId)}><VisibilityIcon /></IconButton></Tooltip>)}
      {canApproveWish(currentUser) && (<Tooltip title="approve"><IconButton color="primary" onClick={() => handleApprove(selectedWish.wishId)}><ThumbUpIcon /></IconButton></Tooltip>)}
      {canScheduleWish(currentUser) && (<Tooltip title="schedule"><IconButton color="primary" onClick={() => handleSchedule(selectedWish.wishId)}><CalendarMonthIcon /></IconButton></Tooltip>)}
      {canDeliverWish(currentUser) && (<Tooltip title="deliver"><IconButton color="primary" onClick={() => handleDeliver(selectedWish.wishId)}><DoneAllIcon /></IconButton></Tooltip>)}
      {canCancelWish(currentUser) && (<Tooltip title="cancel"><IconButton color="primary" onClick={() => handleCancel(selectedWish.wishId)}><BlockIcon /></IconButton></Tooltip>)}
      {canDeleteWish(currentUser) && (<Tooltip title="delete"><IconButton color="primary" onClick={() => handleDelete(selectedWish.wishId)}><DeleteIcon /></IconButton></Tooltip>)}
      {canStateWish(currentUser) && (<Tooltip title="update state"><IconButton color="primary" onClick={() => handleState(selectedWish.wishId)}><PendingIcon /></IconButton></Tooltip>)}
    </>
  );
};
