import { useState } from 'react';

import { EditUserModal } from './EditUserModal';
import { ResetPasswordModal } from './ResetPasswordModal';

import { makeStyles } from 'tss-react/mui';
import { Grid, Button } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  button: {
    width: '100%'
  }
}));

export const UserButtons = (props: any) => {
  const { data, isLoading, mutate } = props;

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const handleOpenEditModal = () => {
    console.log('handleOpenEditModal');
    setOpenEditModal(true);
  };

  const handleOpenPasswordModal = () => {
    console.log('handleOpenPasswordModal');
    setOpenPasswordModal(true);
  };

  const submitEdit = () => {
    console.log('submitEdit');
    setOpenEditModal(false);
    mutate();
  };

  const submitPassword = () => {
    console.log('submitPassword');
    setOpenPasswordModal(false);
    mutate();
  };

  const closeEditModal = () => {
    console.log('closeEdit');
    setOpenEditModal(false);
  };

  const closePasswordModal = () => {
    console.log('closePassword');
    setOpenPasswordModal(false);
  };

  const { classes } = useStyles();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}><Button size="small" variant='outlined' disableElevation className={classes.button} disabled={isLoading} onClick={() => handleOpenEditModal()}>Update Details</Button></Grid>
        <Grid item xs={12}><Button size="small" variant='outlined' disableElevation className={classes.button} disabled={isLoading}  onClick={() => handleOpenPasswordModal()}>Reset Password</Button></Grid>
      </Grid>
      <EditUserModal selectedUser={data} open={openEditModal} closeModal={closeEditModal} handleSubmit={submitEdit} />
      <ResetPasswordModal selectedUser={data} open={openPasswordModal} closeModal={closePasswordModal} handleSubmit={submitPassword} />
    </>

  );
}