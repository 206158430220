import { format } from 'date-fns';
import clsx from 'clsx';

import { useAuthStore } from '../../../hooks/context/useAuthStore';
import { canCreateProduct, canEditProduct, canDeleteProduct } from '../../../helpers/security';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  number: {
    fontFamily: 'Roboto Mono',
  },
}));

export const ProductTable = (props: any) => {
  const { data, isLoading, mutate, selectProduct, selectedProduct, handleCreate, handleEdit, handleDelete } = props;
  const { currentUser } = useAuthStore();

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '100px' }}><span className={classes.heading}>Category</span></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '125px' }}><span className={classes.heading}>Approved By</span></TableCell>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '170px' }}><span className={classes.heading}>SKU</span></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '180px' }}><span className={classes.heading}>Name</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '120px' }}><span className={classes.heading}>Price</span></TableCell>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '175px' }}><span className={classes.heading}>Updated</span></TableCell>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '100px' }}><span className={classes.heading}>Status</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '116px' }}>
              {canCreateProduct(currentUser) && (<Tooltip title="add a new product"><IconButton color="primary" onClick={() => handleCreate()}><AddIcon /></IconButton></Tooltip>)}
              {isLoading && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoading && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any) => (
            <TableRow component="tr" key={row.productId} onClick={() => selectProduct(row.productId)} className={clsx(classes.row, { [classes.selected]: (selectedProduct?.productId === row.productId) })}>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '100px' }}>{row.category}</TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '125px' }}>{row.approvedBy}</TableCell>
              <TableCell className={classes.cell} align="center" style={{ minWidth: '170px' }}><b>{row.sku}</b></TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '180px' }}>{row.name}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '120px' }}><span className={classes.number}>{Number(row.price).toLocaleString()}</span></TableCell>
              <TableCell className={classes.cell} align="center" style={{ minWidth: '175px' }}>{row.updatedAt ? format(new Date(row.updatedAt), 'dd-MMM-yyyy HH:mm') : ''}</TableCell>
              <TableCell className={classes.cell} align="center" style={{ minWidth: '100px' }}>{row.status ? 'active' : 'disabled'}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '116px' }}>
                {canEditProduct(currentUser) && (<Tooltip title="edit"><IconButton color="primary" onClick={() => handleEdit(row.productId)}><EditIcon /></IconButton></Tooltip>)}
                {canDeleteProduct(currentUser) && (<Tooltip title="delete"><IconButton color="primary" onClick={() => handleDelete(row.productId)}><DeleteIcon /></IconButton></Tooltip>)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
