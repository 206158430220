import { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Highcharts.setOptions({
//   colors: Highcharts.getOptions().colors.map(function (color) {
//       return {
//           radialGradient: {
//               cx: 0.5,
//               cy: 0.3,
//               r: 0.7
//           },
//           stops: [
//               [0, color],
//               [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
//           ]
//       };
//   })
// });

export const BarChart = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options = {
    chart: {
        type: 'column'
    },
    title: {
        text: 'Sales Leaderboard',
        align: 'left'
    },
    xAxis: {
        categories: ['Inara', 'Jawad', 'Michael', 'Susan']
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Count'
        },
        stackLabels: {
            enabled: true
        }
    },
    legend: {
        align: 'left',
        x: 70,
        verticalAlign: 'top',
        y: 70,
        floating: true,
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
    },
    tooltip: {
        headerFormat: '<b>{category}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: true
            }
        }
    },
    series: [{
        name: 'Delivered',
        data: [3, 5, 1, 13]
    }, {
        name: 'Approved',
        data: [14, 8, 8, 12]
    }, {
        name: 'Wishes',
        data: [0, 2, 6, 3]
    }]
};
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
};
