import { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';

import { useAuthStore } from '../../../hooks/context/useAuthStore';
import { canScheduleWish, canCancelWish } from '../../../helpers/security';

import { stringToPositiveNumber } from '../../../helpers/validate';
import { WishIcon } from '../../../shared/Wish/WishIcon';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LaunchIcon from '@mui/icons-material/Launch';
import BlockIcon from '@mui/icons-material/Block';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  finalRow: {
    backgroundColor: '#eee'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  disabled: {
    opacity: 0.5,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  number: {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,
  },
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  textInput: {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,
    textAlign: 'right'
  }
}));

export const WishTable = (props: any) => {
  const { year, month, data, isLoading, mutate, selectWish, selectedWish, handleSchedule, handleCancel, handlePanel, cegidTotal, setCegidTotal } = props;
  const { currentUser } = useAuthStore();  
  const [formIndex, setFormIndex] = useState(-1);
  const [formTotal, setFormTotal] = useState<any[]>(data?.map(() => ''));

  const dataTotal = useMemo(() => {
    var total = 0;
    if (data) {
      for (const wish of data) {
        total += Number(wish.price);
      }
    }
    return total;
  }, [data]);

  useEffect(() => {
    var total = 0;
    if (formIndex >= 0) {
      total = Number(formTotal[formIndex]);
      for (var i = formIndex + 1; i < formTotal.length; i++) total += Number(data[i].price || '0');
    }
    setCegidTotal(total);
  }, [data, formTotal, formIndex, setCegidTotal]);

  const handleSubmit = () => {

  };

  const setFormTotals = (index: number, value: string) => {
    const total = [...formTotal];
    for (var i = 0; i < formTotal.length; i++) (total[i] = '');
    const val = stringToPositiveNumber(value);
    if (val > 0) {
      total[index] = val.toString();
      setFormIndex(index);
    } else {
      setFormIndex(-1);
    }
    setFormTotal(total);
  };

  const { classes } = useStyles();
  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '58px' }}></TableCell>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '150px' }}><span className={classes.heading}>CEGID N<sup>o</sup></span></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '200px' }}><span className={classes.heading}>Name</span></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '250px' }}><span className={classes.heading}>Product</span></TableCell>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '120px' }}><span className={classes.heading}>Schedule</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '120px' }}><span className={classes.heading}>Price</span></TableCell>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '150px' }}><span className={classes.heading}>CEGID Total</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '180px' }}>
              {isLoading && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoading && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any, index: number) => (
            <TableRow component="tr" key={row.wishId} onClick={() => selectWish(row.wishId)} className={clsx(classes.row, { [classes.selected]: (selectedWish?.groupId === row.groupId && selectedWish?.wishId === row.wishId) }, { [classes.disabled]: (index <= formIndex) })}>
              <TableCell className={classes.cell} align="center" style={{ minWidth: '58px' }}><WishIcon wishType={row?.wishType} /></TableCell>
              <TableCell className={classes.cell} align="center" style={{ minWidth: '150px' }}>{row.clientId}</TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '200px' }}><b>{row.name}</b></TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '250px' }}><b>{row.productName}</b> {row.bag}</TableCell>
              <TableCell className={classes.cell} align="center" style={{ minWidth: '120px' }}>{row.scheduleDate ? format(new Date(row.scheduleDate), 'dd-MMM-yyyy') : ''}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '120px' }}><span className={classes.number}>{Number(row.price).toLocaleString()}</span></TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '120px' }}><TextField
                id={`formTotal-${index}`}
                label=''
                value={formTotal[index]}
                onChange={(e: any) => setFormTotals(index, e.target.value.trim())}
                placeholder={index > formIndex ? row.price : ''}
                size='small'
                margin='normal'
                variant='outlined'
                fullWidth
                inputProps={{ inputMode: 'numeric', className: classes.textInput }}
                className={classes.textField}
              /></TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '180px' }}>
                { canScheduleWish(currentUser) && (<Tooltip title="schedule"><IconButton color="primary" onClick={() => handleSchedule(row.wishId)}><CalendarMonthIcon /></IconButton></Tooltip> )}
                { canCancelWish(currentUser) && (<Tooltip title="cancel"><IconButton color="primary" onClick={() => handleCancel(row.wishId)}><BlockIcon /></IconButton></Tooltip> )}
                <Tooltip title="view wish"><IconButton color="primary" onClick={() => handlePanel(row.wishId)}><LaunchIcon /></IconButton></Tooltip>
              </TableCell>
            </TableRow>
          ))}
          <TableRow component="tr" className={classes.finalRow}>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '58px' }}></TableCell>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '150px' }}><b>{format(new Date(year, month, 1), 'MMMM yyyy')}</b></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '200px' }}></TableCell>
            <TableCell className={classes.cell} align="left"></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '120px' }}><b>Total: </b></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '120px' }}><b><span className={classes.number}>{dataTotal.toLocaleString()}</span></b></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '120px', paddingRight: 30 }}><span className={classes.number}>{cegidTotal ? cegidTotal.toLocaleString() : ''}</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '180px' }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </form>
  );
};
