import { useState } from "react";
import { useApiUserGroups } from "../../../hooks/admin/useApiUserGroups";
import { useApiGroupList } from "../../../hooks/admin/useApiGroupList";

import { GroupTable } from "./GroupTable";

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(2.5, 3, 3, 3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  progress: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export const Groups = (props: any) => {
  const { selectedUser } = props;
  const { data: dataUserGroups, isLoading: isLoadingUserGroups, mutate: mutateUserGroups } = useApiUserGroups(selectedUser.userId);
  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroups } = useApiGroupList();

  const [selectedUserGroup, setSelectedUserGroup] = useState();
  const [selectedGroup, setSelectedGroup] = useState();

  const mutate = () => {
    mutateUserGroups();
    mutateGroups();
  }
  const selectUserGroup = (id?: string) => {
    console.log('selectUserGroup', id);
    const userGroup = dataUserGroups.find((x: any) => x.groupId === id);
    setSelectedUserGroup(userGroup);
    const group = dataGroupList.find((x: any) => x.groupId === id);
    setSelectedGroup(group);
  }

  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <h2 className={classes.title}>Group Membership</h2>
      <GroupTable data={dataUserGroups} mutate={mutate} isLoading={isLoadingUserGroups || isLoadingGroupList} selectUserGroup={selectUserGroup} selectedUserGroup={selectedUserGroup} selectedGroup={selectedGroup} selectedUser={selectedUser} groupList={dataGroupList} />
    </Paper>
  );
}
