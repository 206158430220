import { format } from 'date-fns';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  tbody: {

  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  cell: {
    padding: theme.spacing(1),
  },
  label: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right'
  },
  stateLabel: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right',
    marginTop: theme.spacing(0.5)
  },
  text: {
    fontSize: '0.85em',
    fontWeight: 400,
  },
}));

export const WishActivity = (props: any) => {
  const { selectedWish } = props;

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}><div className={classes.heading}>Activity</div></TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tbody}>
        <TableRow component="tr">
          <TableCell className={classes.cell} style={{ borderBottom: 'none' }}>
            <Grid container spacing={1} style={classes.grid}>
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
              {selectedWish.createdAt && selectedWish.advisorUserName && (<><Grid item xs={3} className={classes.label}>Created: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.createdAt ? format(new Date(selectedWish.createdAt), 'dd-MMM-yyyy') : ''}</Grid><Grid item xs={1} className={classes.label}>By: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.advisorUserName}</Grid></>)}
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
              {selectedWish.submittedAt && selectedWish.submitUserName && (<><Grid item xs={3} className={classes.label}>{selectedWish.isSubmitted === 'true' ? 'Submitted' : 'Rejected'}: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.submittedAt ? format(new Date(selectedWish.submittedAt), 'dd-MMM-yyyy') : ''}</Grid><Grid item xs={1} className={classes.label}>By: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.submitUserName}</Grid></>)}
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
              {selectedWish.reviewedAt && selectedWish.approveUserName && (<><Grid item xs={3} className={classes.label}>Reviewed: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.reviewedAt ? format(new Date(selectedWish.reviewedAt), 'dd-MMM-yyyy') : ''}</Grid><Grid item xs={1} className={classes.label}>By: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.reviewUserName}</Grid></>)}
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
              {selectedWish.approvedAt && selectedWish.approveUserName && (<><Grid item xs={3} className={classes.label}>{selectedWish.isApproved === 'true' ? 'Approved' : 'Denied'}: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.approvedAt ? format(new Date(selectedWish.approvedAt), 'dd-MMM-yyyy') : ''}</Grid><Grid item xs={1} className={classes.label}>By: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.approveUserName}</Grid></>)}
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
              {selectedWish.scheduleDate && (selectedWish.scheduleUserName && <><Grid item xs={3} className={classes.label}>Scheduled: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.scheduleDate ? format(new Date(selectedWish.scheduleDate), 'dd-MMM-yyyy') : ''}</Grid><Grid item xs={1} className={classes.label}>By: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.scheduleUserName}</Grid></>)}
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
              {selectedWish.deliveredAt && selectedWish.deliverUserName && (<><Grid item xs={3} className={classes.label}>Delivered: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.deliveredAt ? format(new Date(selectedWish.deliveredAt), 'dd-MMM-yyyy') : ''}</Grid><Grid item xs={1} className={classes.label}>By: </Grid><Grid item xs={4} className={classes.text}>{selectedWish.deliverUserName}</Grid></>)}
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
