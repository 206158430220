import { useMemo } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';

import { useAuthStore } from '../../../hooks/context/useAuthStore';
import { canScheduleWish, canCancelWish, canDeliverWish } from '../../../helpers/security';
import { EState } from '../../../services/types';
import { WishIcon } from '../../../shared/Wish/WishIcon';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LaunchIcon from '@mui/icons-material/Launch';
import BlockIcon from '@mui/icons-material/Block';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    minWidth: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  finalRow: {
    backgroundColor: '#eee'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  delivered: {
    opacity: 0.5,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  number: {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,
  }
}));

export const WishTable = (props: any) => {
  const { year, month, day, data, selectWish, selectedWish, handleSchedule, handleDeliver, handleCancel, handlePanel } = props;
  const { currentUser } = useAuthStore();

  const total = useMemo(() => {
    var price = 0;
    if (data) {
      for (const wish of data) {
        price += Number(wish.price);
      }
    }
    return price;
  }, [data]);

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          {data?.map((row: any) => (
            <TableRow component="tr" key={row.wishId} onClick={() => selectWish(row.wishId)} className={clsx(classes.row, { [classes.selected]: (selectedWish?.groupId === row.groupId && selectedWish?.wishId === row.wishId) }, { [classes.delivered]: (row.state === EState.completed) })}>
              <TableCell className={classes.cell} align="center" style={{ minWidth: '58px' }}><WishIcon wishType={row?.wishType} /></TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '130px' }}>{row.clientId}</TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '200px' }}><b>{row.name}</b></TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '160px' }}>{row.phone}</TableCell>
              <TableCell className={classes.cell} align="left" style={{ minWidth: '220px' }}>{row.productName} {row.bag}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '120px' }}><span className={classes.number}>{Number(row.price).toLocaleString()}</span></TableCell>
              <TableCell className={classes.cell} align="center" style={{ minWidth: '180px' }}>{row.advisorUserName}</TableCell>
              <TableCell className={classes.cell} align="right" style={{ minWidth: '200px' }}>
                {canScheduleWish(currentUser) && (<Tooltip title="schedule"><IconButton color="primary" onClick={() => handleSchedule(row.wishId)}><CalendarMonthIcon /></IconButton></Tooltip>)}
                {canDeliverWish(currentUser) && (<Tooltip title="deliver"><IconButton color="primary" onClick={() => handleDeliver(row.wishId)}><DoneAllIcon /></IconButton></Tooltip>)}
                {canCancelWish(currentUser) && (<Tooltip title="cancel"><IconButton color="primary" onClick={() => handleCancel(row.wishId)}><BlockIcon /></IconButton></Tooltip>)}
                <Tooltip title="view wish"><IconButton color="primary" onClick={() => handlePanel(row.wishId)}><LaunchIcon /></IconButton></Tooltip>
              </TableCell>
            </TableRow>
          ))}
          <TableRow component="tr" className={classes.finalRow}>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '58px' }}></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '330px' }} colSpan={2}><b>{format(new Date(year, month, day), 'PPPP')}</b></TableCell>
            <TableCell className={classes.cell} align="left" style={{ minWidth: '160px' }}></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '240px' }}><b>Total: </b></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '120px' }}><b><span className={classes.number}>{total.toLocaleString()}</span></b></TableCell>
            <TableCell className={classes.cell} align="center" style={{ minWidth: '180px' }}></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '200px' }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
