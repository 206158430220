import React, { createContext, useEffect, useState } from 'react';
import { getLogoutLink, getMe, ping } from '../services/auth';
import { useInterval } from '../hooks/utils/useInterval';

export interface IUser {
  userId: string;
  email: string;
  name: string;
  username: string;
  picture: string;
  updatedAt: string;
  loginAt: string;
  numLogins: number;
  isEmailVerified: boolean;
  isAuthorized: boolean;
  isSuperAdmin: boolean;
  isAdministrator: boolean;
  isDirector: boolean;
  isManager: boolean;
  isReviewer: boolean;
  isMetier: boolean;
  isAdvisor: boolean;
  isCashier: boolean;
}

interface IAuthContext {
  isLoading: boolean;
  isAuthorized: boolean;
  idToken?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  currentUser: IUser;
  setCurrentUser: (user: IUser) => void;
  setIdToken: (token: string) => void;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  logout: (redirectTo: string, error: string) => void;
  mutate: () => void;
}

const USER_DEFAULT: IUser = {
  userId: '',
  email: '',
  name: '',
  username: '',
  picture: '',
  updatedAt: '',
  loginAt: '',
  numLogins: 0,
  isEmailVerified: false,
  isAuthorized: false,
  isSuperAdmin: false,
  isAdministrator: false,
  isDirector: false,
  isManager: false,
  isReviewer: false,
  isMetier: false,
  isAdvisor: false,
  isCashier: false,
};

const CONTEXT_DEFAULT = {
  isLoading: false,
  isAuthorized: false,
  idToken: '',
  accessToken: '',
  refreshToken: '',
  currentUser: USER_DEFAULT,
  setCurrentUser: () => { },
  setIdToken: () => { },
  setAccessToken: () => { },
  setRefreshToken: () => { },
  logout: () => { },
  mutate: () => { },
};

export const AuthContext = createContext<IAuthContext>(CONTEXT_DEFAULT);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const isAuthorized = localStorage.getItem('accessToken')
  const [idToken, _setIdToken] = useState(localStorage.getItem('idToken'));
  const [accessToken, _setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [refreshToken, _setRefreshToken] = useState(localStorage.getItem('refreshToken'));
  const [currentUser, setCurrentUser] = useState<IUser>(USER_DEFAULT);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('user', currentUser);
  }, [currentUser]);
  
  useInterval(() => {
    if (isAuthorized) {
      ping().catch((e: any) => {
        console.log('ping', e.message);
      });
    }
  }, 60000);

  useEffect(() => {
    if (accessToken && !currentUser.isAuthorized) {
      setIsLoading(true);
      getMe().then(res => {
        setCurrentUser({ ...res, isAuthorized: true });
      }).catch((e: any) => {
        console.log('getMe', e.message);
      }).finally(() => { setIsLoading(false); });
    }
  }, [accessToken, currentUser])

  const mutate = () => {
    setIsLoading(true);
    getMe().then(res => {
      setCurrentUser({ ...res, isAuthorized: true });
    }).catch((e: any) => {
      console.log('getMe', e.message);
    }).finally(() => { setIsLoading(false); });
  }

  const setIdToken = (token: string) => {
    localStorage.setItem('idToken', token);
    _setIdToken(token);
  };

  const setAccessToken = (token: string) => {
    localStorage.setItem('accessToken', token);
    _setAccessToken(token);
  };

  const setRefreshToken = (token: string) => {
    localStorage.setItem('refreshToken', token);
    _setRefreshToken(token);
  };

  const logout = (redirectTo: string, error: string) => {
    getLogoutLink(redirectTo, error).then((res: any) => {
      console.log('logout', res);
      _setIdToken(null);
      _setAccessToken(null);
      _setRefreshToken(null);
      setCurrentUser(USER_DEFAULT);
      localStorage.removeItem("idToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.clear();
      window.location.href = res;
    }).catch((e: any) => {
      console.log('Unable to logout', e.mesage);
    });
  };

  const value = {
    currentUser,
    setCurrentUser,
    isAuthorized: Boolean(isAuthorized),
    idToken,
    accessToken,
    refreshToken,
    setIdToken,
    setAccessToken,
    setRefreshToken,
    logout,
    isLoading,
    mutate
  };
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
