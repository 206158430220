import { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const SplineChart = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options = {
    chart: {
        type: 'spline'
    },
    title: {
        text: 'Product Sales'
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            // don't display the year
            month: '%e. %b',
            year: '%b'
        },
        title: {
            text: 'Date'
        }
    },
    yAxis: {
        title: {
            text: 'Products Sold'
        },
        min: 0
    },
    tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: '{point.x:%e. %b}: {point.y:.2f} m'
    },

    plotOptions: {
        series: {
            marker: {
                symbol: 'circle',
                fillColor: '#FFFFFF',
                enabled: true,
                radius: 2.5,
                lineWidth: 1,
                lineColor: null
            }
        }
    },

    colors: ['#6CF', '#39F', '#06C', '#036', '#000'],

    // Define the data points. All series have a year of 1970/71 in order
    // to be compared on the same x axis.
    series: [
        {
            name: 'Kelly Classic',
            data: [
                ['1970-11-05', 0],
                ['1970-11-12', 0.1],
                ['1970-11-21', 0.15],
                ['1970-11-22', 0.19],
                ['1970-11-27', 0.17],
                ['1970-11-30', 0.27],
                ['1970-12-02', 0.25],
                ['1970-12-04', 0.27],
                ['1970-12-05', 0.26],
                ['1970-12-06', 0.25],
                ['1970-12-07', 0.26],
                ['1970-12-08', 0.26],
                ['1970-12-09', 0.25],
                ['1970-12-10', 0.25],
                ['1970-12-11', 0.25],
                ['1970-12-12', 0.26],
                ['1970-12-22', 0.22],
                ['1970-12-23', 0.22],
                ['1970-12-24', 0.22],
                ['1970-12-25', 0.24],
                ['1970-12-26', 0.24],
                ['1970-12-27', 0.24],
                ['1970-12-28', 0.24],
                ['1970-12-29', 0.24],
                ['1970-12-30', 0.22],
                ['1970-12-31', 0.18],
                ['1971-01-01', 0.17],
                ['1971-01-02', 0.23],
                ['1971-01-09', 0.5],
                ['1971-01-10', 0.5],
                ['1971-01-11', 0.53],
                ['1971-01-12', 0.48],
                ['1971-01-13', 0.4],
                ['1971-01-17', 0.36],
                ['1971-01-22', 0.69],
                ['1971-01-23', 0.62],
                ['1971-01-29', 0.72],
                ['1971-02-02', 0.95],
                ['1971-02-10', 1.73],
                ['1971-02-15', 1.76],
                ['1971-02-26', 2.18],
                ['1971-03-02', 2.22],
                ['1971-03-06', 2.13],
                ['1971-03-08', 2.11],
                ['1971-03-09', 2.12],
                ['1971-03-10', 2.11],
                ['1971-03-11', 2.09],
                ['1971-03-12', 2.08],
                ['1971-03-13', 2.08],
                ['1971-03-14', 2.07],
                ['1971-03-15', 2.08],
                ['1971-03-17', 2.12],
                ['1971-03-18', 2.19],
                ['1971-03-21', 2.11],
                ['1971-03-24', 2.1],
                ['1971-03-27', 1.89],
                ['1971-03-30', 1.92],
                ['1971-04-03', 1.9],
                ['1971-04-06', 1.95],
                ['1971-04-09', 1.94],
                ['1971-04-12', 2],
                ['1971-04-15', 1.9],
                ['1971-04-18', 1.84],
                ['1971-04-21', 1.75],
                ['1971-04-24', 1.69],
                ['1971-04-27', 1.64],
                ['1971-04-30', 1.64],
                ['1971-05-03', 1.58],
                ['1971-05-06', 1.52],
                ['1971-05-09', 1.43],
                ['1971-05-12', 1.42],
                ['1971-05-15', 1.37],
                ['1971-05-18', 1.26],
                ['1971-05-21', 1.11],
                ['1971-05-24', 0.92],
                ['1971-05-27', 0.75],
                ['1971-05-30', 0.55],
                ['1971-06-03', 0.35],
                ['1971-06-06', 0.21],
                ['1971-06-09', 0]
            ]
        },
        {
            name: 'Birkin Classic',
            data: [
                ['1970-11-03', 0],
                ['1970-11-09', 0],
                ['1970-11-12', 0.03],
                ['1970-11-15', 0],
                ['1970-11-24', 0],
                ['1970-11-27', 0.06],
                ['1970-11-30', 0.05],
                ['1970-12-03', 0.05],
                ['1970-12-06', 0.07],
                ['1970-12-09', 0.09],
                ['1970-12-15', 0.09],
                ['1970-12-18', 0.13],
                ['1970-12-21', 0.17],
                ['1970-12-24', 0.32],
                ['1970-12-27', 0.62],
                ['1971-01-03', 0.60],
                ['1971-01-09', 0.63],
                ['1971-01-12', 0.74],
                ['1971-01-15', 0.80],
                ['1971-01-18', 0.97],
                ['1971-01-21', 0.87],
                ['1971-01-24', 0.98],
                ['1971-01-27', 0.87],
                ['1971-01-30', 0.98],
                ['1971-02-03', 1.09],
                ['1971-02-06', 1.24],
                ['1971-02-09', 1.26],
                ['1971-02-12', 1.21],
                ['1971-02-15', 1.12],
                ['1971-02-18', 1.35],
                ['1971-02-21', 1.65],
                ['1971-02-24', 1.64],
                ['1971-02-27', 1.58],
                ['1971-03-03', 1.55],
                ['1971-03-06', 1.62],
                ['1971-03-09', 1.55],
                ['1971-03-12', 1.69],
                ['1971-03-15', 1.70],
                ['1971-03-18', 1.95],
                ['1971-03-21', 1.91],
                ['1971-03-27', 2.08],
                ['1971-03-30', 2.17],
                ['1971-04-03', 2.09],
                ['1971-04-12', 2.04],
                ['1971-04-15', 1.91],
                ['1971-04-18', 1.93],
                ['1971-04-21', 1.79],
                ['1971-04-24', 1.72],
                ['1971-04-27', 1.79],
                ['1971-05-03', 1.74],
                ['1971-05-06', 1.66],
                ['1971-05-09', 1.56],
                ['1971-05-12', 1.37],
                ['1971-05-15', 1.20],
                ['1971-05-18', 1.18],
                ['1971-05-21', 0.93],
                ['1971-05-24', 0.77],
                ['1971-05-27', 0.63],
                ['1971-05-30', 0.47],
                ['1971-06-03', 0.22],
                ['1971-06-06', 0.0]
            ]
        },
        {
            name: 'Kelly Exotic',
            data: [
                ['1970-10-10', 0],
                ['1970-11-18', 0.2],
                ['1970-11-21', 0.08],
                ['1970-11-25', 0.60],
                ['1970-12-03', 0.11],
                ['1970-12-06', 0.49],
                ['1970-12-18', 0.38],
                ['1970-12-21', 0.70],
                ['1970-12-25', 0.81],
                ['1970-12-30', 0.77],
                ['1971-01-09', 0.65],
                ['1971-01-12', 0.71],
                ['1971-01-21', 0.86],
                ['1971-01-24', 1.07],
                ['1971-01-27', 1.19],
                ['1971-01-30', 1.12],
                ['1971-02-03', 1.31],
                ['1971-02-06', 1.43],
                ['1971-02-09', 1.33],
                ['1971-02-12', 1.41],
                ['1971-02-15', 1.49],
                ['1971-02-18', 1.46],
                ['1971-02-21', 1.55],
                ['1971-02-24', 1.58],
                ['1971-02-27', 1.61],
                ['1971-03-03', 1.80],
                ['1971-03-06', 1.64],
                ['1971-03-15', 1.66],
                ['1971-03-16', 1.91],
                ['1971-03-21', 1.86],
                ['1971-03-23', 2.08],
                ['1971-03-31', 2.01],
                ['1971-04-11', 1.86],
                ['1971-04-15', 1.82],
                ['1971-04-19', 1.81],
                ['1971-04-25', 1.79],
                ['1971-05-05', 1.43],
                ['1971-05-08', 1.13],
                ['1971-05-12', 0.98],
                ['1971-05-15', 0.71],
                ['1971-05-18', 0.50],
                ['1971-05-21', 0.28],
                ['1971-05-24', 0.09],
                ['1971-05-25', 0.0]
            ]
        }
    ]
};
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
};
