import { EWishType } from '../../services/types';

import Tooltip from '@mui/material/Tooltip';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FlareIcon from '@mui/icons-material/Flare';

export const WishIcon = (props: any) => {
  const { wishType } = props;
  switch (wishType) {
    case EWishType.wish: return <Tooltip title='Leather Wish' ><AutoAwesomeIcon color="info" /></Tooltip>;
    case EWishType.appointment: return <Tooltip title='Leather Appointment' ><FlareIcon color="primary" /></Tooltip>;
  }
  return <></>;
};
