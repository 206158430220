
import { UserDetails } from './UserDetails';
import { UserButtons } from './UserButtons';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const UserProfile = (props: any) => {
  const { data, isLoading, mutate } = props;
  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1} className={classes.grid} justifyContent='space-between' >
        <Grid item xs={11} sm={11} md={11} lg={7}>
          <UserDetails data={data} isLoading={isLoading} mutate={mutate} />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          {isLoading && (
            <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={18} /></IconButton></Tooltip>
          )}
          {!isLoading && (
            <Tooltip title="refresh"><IconButton color="primary" size="small" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <UserButtons data={data} isLoading={isLoading} mutate={mutate} />
        </Grid>
      </Grid>
    </Paper>
  );
}