import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useApiUserDetails } from '../../hooks/admin/useApiUserDetails';

import { AppLayout } from '../../shared/AppLayout/AppLayout';

import { Profile } from './Profile';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginBottom: theme.spacing(3)
  },
  titles: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button1: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button2: {
    marginTop: theme.spacing(1),
  }
}));

export const User = () => {
  const { userId = '' } = useParams();
  const { data } = useApiUserDetails(userId);

  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const handleBackToUsers = () => {
    navigate('/user');
  }

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between" alignItems="flex-end" className={classes.grid}>
        <Grid item>
          <div className={classes.titles}>
            <h1 className={classes.title}>User Details</h1>
            <h4 className={classes.subtitle}>{data?.email}</h4>
          </div>
          </Grid>
        <Grid item>
          <Button size="small" variant="outlined" color="primary" disableElevation startIcon={<ArrowBackIosNewIcon />} className={classes.button2} onClick={() => handleBackToUsers()}>Back to User List</Button>
        </Grid>
      </Grid>          
      {userId && isAuthorized && data && (
        <>
          <Profile userId={userId} />
        </>
      )}
    </AppLayout>
  );
};
