import { EState } from '../../services/types';
import { useAuthStore } from '../../hooks/context/useAuthStore';
import { canSubmitWish, canReviewWish, canApproveWish, canScheduleWish, canDeliverWish, canStateWish } from '../../helpers/security';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
// import BlockIcon from '@mui/icons-material/Block';
import PendingIcon from '@mui/icons-material/Pending';

export const WishActionIcon = (props: any) => {
  const { selectedWish, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleState } = props;
  const { currentUser } = useAuthStore();
  return (
    <>
      {selectedWish?.state === EState.created && canSubmitWish(currentUser) && (<Tooltip title="submit"><IconButton color="primary" onClick={() => handleSubmit(selectedWish.wishId)}><PublishIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.submitted && canReviewWish(currentUser)  && (<Tooltip title="review"><IconButton color="primary" onClick={() => handleReview(selectedWish.wishId)}><VisibilityIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.rejected && canSubmitWish(currentUser)  && (<Tooltip title="submit"><IconButton color="primary" onClick={() => handleSubmit(selectedWish.wishId)}><PublishIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.reviewed && canApproveWish(currentUser)  && (<Tooltip title="approve"><IconButton color="primary" onClick={() => handleApprove(selectedWish.wishId)}><ThumbUpIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.approved && canScheduleWish(currentUser)  && (<Tooltip title="schedule"><IconButton color="primary" onClick={() => handleSchedule(selectedWish.wishId)}><CalendarMonthIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.denied && canApproveWish(currentUser)  && (<Tooltip title="approve"><IconButton color="primary" onClick={() => handleApprove(selectedWish.wishId)}><ThumbUpIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.scheduled && canScheduleWish(currentUser)  && (<Tooltip title="schedule"><IconButton color="primary" onClick={() => handleSchedule(selectedWish.wishId)}><CalendarMonthIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.scheduled && canDeliverWish(currentUser)  && (<Tooltip title="deliver"><IconButton color="primary" onClick={() => handleDeliver(selectedWish.wishId)}><DoneAllIcon /></IconButton></Tooltip>)}
      {/* {selectedWish?.state === EState.scheduled && canCancelWish(currentUser)  && (<Tooltip title="cancel"><IconButton color="primary" onClick={() => handleCancel(selectedWish.wishId)}><BlockIcon /></IconButton></Tooltip>)} */}
      {
        (selectedWish?.state === EState.completed ||
          selectedWish?.state === EState.expired ||
          selectedWish?.state === EState.cancelled ||
          selectedWish?.state === EState.failed)  && canStateWish(currentUser) && 
        (<Tooltip title="update state"><IconButton color="primary" onClick={() => handleState(selectedWish.wishId)}><PendingIcon /></IconButton></Tooltip>)
      }
    </>
  );
};
