import clsx from 'clsx';
import { format } from 'date-fns';

import { useAuthStore } from '../../hooks/context/useAuthStore';
import { canEditBudget } from '../../helpers/security';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
// import LaunchIcon from '@mui/icons-material/Launch';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  icons: {
    width: '100%'
  },
  table: {
    width: '100%',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
    lineHeight: theme.spacing(5)
  },
  cell: {
    padding: theme.spacing(1, 2),
  },
  label: {
    fontWeight: 400,
    textAlign: 'right'
  },
  stateLabel: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right',
    marginTop: theme.spacing(0.5)
  },
  text: {
    fontSize: '0.85em',
    fontWeight: 400,
  },
  number1: {
    fontWeight: 600,
    fontFamily: 'Roboto Mono',
    textAlign: 'right'
  },
  numberDiv1: {
    fontWeight: 600,
    fontFamily: 'Roboto Mono',
    textAlign: 'right',
    borderBottom: 'solid 1px #eee'
  },
  number2: {
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
    textAlign: 'right'
  },
  numberDiv2: {
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
    textAlign: 'right',
    borderBottom: 'solid 1px #eee'
  },
  strikeThrough: {
    textDecoration: 'line-through',
    opacity: '0.5',
  },
  error: {
    color: theme.palette.error.main
  },
  success: {
    color: theme.palette.success.main
  }
}));

export const WishBudgetPlanning = (props: any) => {
  const { selectedBudget, year, month, isLoading, mutate, handleEdit } = props;
  const { currentUser } = useAuthStore();

  const selectedDate = new Date(year, month, 1);

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}>
            <Grid container justifyContent="space-between" className={classes.icons}>
              <Grid item><div className={classes.heading}>{format(selectedDate, "MMMM yyyy")}</div></Grid>
              <Grid item>
                { canEditBudget(currentUser) && (<Tooltip title="edit"><IconButton color="primary" onClick={() => handleEdit(year, month)}><EditIcon /></IconButton></Tooltip>)}
                {isLoading && (
                  <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
                )}
                {!isLoading && (
                  <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
                )}
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow component="tr">
          <TableCell className={classes.cell}>
            <Grid container spacing={1} style={classes.grid}>
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
              <Grid item xs={6} className={classes.label}>Kelly Budget: </Grid><Grid item xs={3} className={classes.numberDiv1}>{selectedBudget?.kelly?.toLocaleString()}</Grid><Grid item xs={3} className={classes.numberDiv1}></Grid>
              <Grid item xs={6} className={classes.label}>Kelly Classic Sales: </Grid><Grid item xs={3} className={classes.number2}><span className={clsx({ [classes.strikeThrough]: (selectedBudget?.kellyClassicCegid) })}>{selectedBudget?.kellyClassicSales?.toLocaleString()}</span></Grid><Grid item xs={3} className={classes.number2}>{selectedBudget?.kellyClassicCegid ? selectedBudget.kellyClassicCegid.toLocaleString() : ''}</Grid>
              <Grid item xs={6} className={classes.label}>Kelly Exotic Sales: </Grid><Grid item xs={3} className={classes.numberDiv2}><span className={clsx({ [classes.strikeThrough]: (selectedBudget?.kellyExoticCegid) })}>{selectedBudget?.kellyExoticSales?.toLocaleString()}</span></Grid><Grid item xs={3} className={classes.numberDiv2}>{selectedBudget?.kellyExoticCegid ? selectedBudget.kellyExoticCegid.toLocaleString() : ''}</Grid>
              <Grid item xs={6} className={classes.label}>Kelly Available: </Grid><Grid item xs={3} className={classes.number1}>
                {(selectedBudget?.kellyAvailable < 0) && (<span className={classes.error}>{selectedBudget?.kellyAvailable?.toLocaleString()}</span>)}
                {(selectedBudget?.kellyAvailable === 0) && (<span>{selectedBudget?.kellyAvailable?.toLocaleString()}</span>)}
                {(selectedBudget?.kellyAvailable > 0) && (<span className={classes.success}>{selectedBudget?.kellyAvailable?.toLocaleString()}</span>)}
              </Grid><Grid item xs={3} className={classes.number1}></Grid>
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow component="tr">
          <TableCell className={classes.cell}>
            <Grid container spacing={1} style={classes.grid}>
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
              <Grid item xs={6} className={classes.label}>Birkin Budget: </Grid><Grid item xs={3} className={classes.numberDiv1}>{selectedBudget?.birkin?.toLocaleString()}</Grid><Grid item xs={3} className={classes.numberDiv1}></Grid>
              <Grid item xs={6} className={classes.label}>Birkin Classic Sales: </Grid><Grid item xs={3} className={classes.number2}><span className={clsx({ [classes.strikeThrough]: (selectedBudget?.birkinClassicCegid) })}>{selectedBudget?.birkinClassicSales?.toLocaleString()}</span></Grid><Grid item xs={3} className={classes.number2}>{selectedBudget?.birkinClassicCegid ? selectedBudget.birkinClassicCegid.toLocaleString() : ''}</Grid>
              <Grid item xs={6} className={classes.label}>Birkin Exotic Sales: </Grid><Grid item xs={3} className={classes.numberDiv2}><span className={clsx({ [classes.strikeThrough]: (selectedBudget?.birkinExoticCegid) })}>{selectedBudget?.birkinExoticSales?.toLocaleString()}</span></Grid><Grid item xs={3} className={classes.numberDiv2}>{selectedBudget?.birkinExoticCegid ? selectedBudget.birkinExoticCegid.toLocaleString() : ''}</Grid>
              <Grid item xs={6} className={classes.label}>Birkin Available: </Grid><Grid item xs={3} className={classes.number1}>
                {(selectedBudget?.birkinAvailable < 0) && (<span className={classes.error}>{selectedBudget?.birkinAvailable?.toLocaleString()}</span>)}
                {(selectedBudget?.birkinAvailable === 0) && (<span>{selectedBudget?.birkinAvailable?.toLocaleString()}</span>)}
                {(selectedBudget?.birkinAvailable > 0) && (<span className={classes.success}>{selectedBudget?.birkinAvailable?.toLocaleString()}</span>)}
              </Grid><Grid item xs={3} className={classes.number1}></Grid>
              <Grid item xs={6} className={classes.label}></Grid><Grid item xs={6} className={classes.text}></Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>

  );
};
