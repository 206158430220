import { Typography, CircularProgress, Theme, Grid } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

import appLogo from "../../assets/logos/leatherwish-logo.svg";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    zIndex: 2000,
    position: 'relative',
  },
  logoTitle: {
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: 'Roboto Slab',
    fontSize: '2rem',
    fontWeight: 500,
    '& span': {
      color: 'rgba(255, 255, 255, 0.7)',
      fontFamily: 'Roboto',
      fontSize: '2rem',
      fontWeight: 300,
    }
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      height: 'auto',
      width: '84px',
    },
  },
  progress: {
    width: 130,
    marginLeft: 30,
    marginRight: 30,
    textAlign: 'center'
  },
}),
);

export const Holding = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <div className={classes.logoWrapper}>
            <div><img src={appLogo} alt="" /></div>
            <Typography variant="h1" className={classes.logoTitle}>Leather<span>Wish</span></Typography>
          </div>
        </Grid>
        <Grid item>
            <div className={classes.progress}>
              <CircularProgress color="primary" size={35} />
            </div>
        </Grid>
      </Grid>
    </div>
  );
}