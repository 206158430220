import useSWR from 'swr';

import {useAuthStore} from "../context/useAuthStore";
import { getWishes } from "../../services/wish";


const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

const apiWishList = ([_, groupId]: any[]) => getWishes(groupId);

export const useApiWishList = (groupId: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && groupId ? ['useApiWishList', groupId] : null, apiWishList, options);
  return {
    data: data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};

