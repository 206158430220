import clsx from 'clsx';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    // cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
}));

export const GroupTable = (props: any) => {
  const { data, isLoading, mutate, selectUserGroup, selectedGroup, groupList } = props;

  const getGroup = (groupId: string) => groupList?.find((x: any) => x.groupId === groupId);

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Group</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Role</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Status</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              {isLoading && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoading && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((row: any) => (
            <TableRow component="tr" key={row.groupId} onClick={() => selectUserGroup(row.groupId)} className={clsx(classes.row, { [classes.selected]: (selectedGroup?.groupId === row.groupId) })}>
              <TableCell className={classes.cell} align="left"><b>{getGroup(row.groupId)?.name}</b></TableCell>
              <TableCell className={classes.cell} align="left"><b>{row.roles}</b></TableCell>
              <TableCell className={classes.cell} align="left">{row.status ? 'active' : 'disabled'}</TableCell>
              <TableCell className={classes.cell} align="right">
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
