import { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Highcharts.setOptions({
//   colors: Highcharts.getOptions().colors.map(function (color) {
//       return {
//           radialGradient: {
//               cx: 0.5,
//               cy: 0.3,
//               r: 0.7
//           },
//           stops: [
//               [0, color],
//               [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
//           ]
//       };
//   })
// });

export const PieChart = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Client Nationality'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<span style="font-size: 1.2em"><b>{point.name}</b>' +
                    '</span><br>' +
                    '<span style="opacity: 0.6">{point.percentage:.1f} ' +
                    '%</span>',
                connectorColor: 'rgba(128,128,128,0.5)'
            }
        }
    },
    series: [{
        name: 'Share',
        data: [
            { name: 'Russian', y: 938899 },
            { name: 'Asian', y: 1229600 },
            { name: 'European', y: 325251 },
            { name: 'Other', y: 238751 }
        ]
    }]
};
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
};
